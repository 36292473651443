import React, { useState, useRef, useEffect } from "react";
import closeAuditData from "../../../../Json/QAAudit/close-audit.json";
import { Badge, Card, Form } from "react-bootstrap";
import DataTable from "react-data-table-component";
import TableShimmerLoader from "../../../_common/ShimmerTable";
import { useDispatch, useSelector } from "react-redux";
import { getClosedCasesList, getFinalClosedCasesList, getLoading } from "../../../../application/selector/indexSelector";
import useAuth from "../../../../hooks/useAuth";
import { format } from "date-fns";
import LoadingBar from 'react-top-loading-bar';

export default function RecentlyWorked() {
    const dispatch = useDispatch();
    const { auth } = useAuth()
    const ClosedCasesList = useSelector(getFinalClosedCasesList)
    const { loading, value } = useSelector(getLoading);
    const columns = [
        {
            name: "Id",
            selector: (row, index) => <h6 className="fw-bold">{index + 1}</h6>, // Adding 1 to make it 1-based
            sortable: false, // Disable sorting as this is static
            width: "80px" // Optional: To control column width
        },
        {
            name: "Case ID",
            selector: (row) => (
                <h6 >{row.lmsCaseId}</h6>
            ),
            sortable: true,
        },
        {
            name: "Project Name",
            selector: (row) => row.projectName,
            sortable: true,
        }, {
            name: "Form Name",
            selector: (row) => row.formName,
            sortable: true,
        }, 
        {
            name: "Final Score",
            selector: (row) => row.finalScore,
            sortable: true,
        },
        {
            name: "Is ATA Done?",
            selector: (row) => (
                <h6 className="text-wrap pt-1 pb-1 fs-12 fw-bold">{row.isATADone ?'Yes' : 'No'}</h6>
            ),
            sortable: true,
        },
        {
            name: " Auditor's Comment",
            selector: (row) => (
                <h6 className="text-wrap pt-1 pb-1 fs-12">{row.overAllComment ? row.overAllComment : '-'}</h6>
            ),
            sortable: true,
        },
        {
            name: "Audit Date",
            selector: (row) =>format(new Date(row.updatedOn), "dd MMM yyyy hh:mm a"),
            sortable: true,
        },
        {
            name: "Audit Status",
            selector: (row) => (
                <Badge bg="success" pill>Closed</Badge>
            )
        }
    ];

    // SEARCH FILTER TABLE DATA
    const [searchTerm, setSearchTerm] = useState('');
    const filteredCloseAudit = ClosedCasesList?.filter((item) =>
        Object.values(item).some((value) =>
            value && value.toString().toLowerCase().includes(searchTerm.toLowerCase())
        )
    );

    // PROGRESS TOP LOADER
    const loadingBarRef = useRef(null);
    useEffect(() => {
        if (loadingBarRef.current) {
            // Start the loading bar
            loadingBarRef.current.continuousStart();

            // Simulate a load completion or manage dynamically
            const timer = setTimeout(() => {
                loadingBarRef.current.complete();
            }, 3000); // Adjust timing as needed

            // Cleanup timer
            return () => clearTimeout(timer);
        }
    }, []);

    return (
        <React.Fragment>
            <LoadingBar color="#506fd9" ref={loadingBarRef} shadow={true} />
            <div className="d-md-flex align-items-center justify-content-between mb-4">
                <div>
                    <h4 className="fs-16">Close Audit</h4>
                </div>
            </div>
          
            <Card className="card-one">
                <Card.Header className="align-items-center justify-content-between">
                    <h4 className="main-title fs-14 mb-0">List of Close Audit</h4>
                    <div className="custom-dropdown-wrapper">
                        <div className="custom-drop-down z-index-2 wt-300">
                            <span className="dropdown-icon bg-white"><i className="ri-search-line"></i></span>
                            <Form.Control
                                type="text"
                                placeholder="Search..."
                                value={searchTerm}
                                onChange={(e) => setSearchTerm(e.target.value)}
                            />
                        </div>
                    </div>
                </Card.Header>
                <Card.Body>
                    {filteredCloseAudit && filteredCloseAudit !== undefined && (
                        <DataTable
                            columns={columns}
                            data={filteredCloseAudit}
                            fixedHeader
                            search={true}
                            highlightOnHover
                            pagination
                        ></DataTable>
                    )}
                    {!loading && filteredCloseAudit === undefined && (
                        <TableShimmerLoader colNames={columns?.map((column) => column.name)} colsCount={1} />
                    )}
                    {loading &&
                        value === "closeAuditList" && filteredCloseAudit === undefined && (
                            <TableShimmerLoader colNames={columns?.map((column) => column.name)} colsCount={1} />
                        )}
                </Card.Body>
            </Card>
        </React.Fragment>
    );
}
