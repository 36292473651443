import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import "../../../QAAudit/AuditDetail/audit-detail.scss";
import Header from "../../../../layouts/Header";
import { Doughnut } from 'react-chartjs-2';
import { Chart as ChartJS, Title, Tooltip, Legend, ArcElement, CategoryScale } from 'chart.js';
import { Badge, Button, Card, Col, Row, Form, Nav, Tab } from "react-bootstrap";
import { Link, useNavigate, useParams } from "react-router-dom";
import CaseData from "../../AuditDetail/pages/case-data";
import UploadedDocument from "../../AuditDetail/pages/uploaded-documents";
import ATAScore from "../../AuditDetail/pages/ATAScore/ata-score";
import ChangeLogModal from "../../AuditDetail/pages/change-log";
import useAuth from "../../../../hooks/useAuth";
import { getAuditDetails, getAuditErrors, getMapAuditFormErrorsList, getLoading } from "../../../../application/selector/indexSelector";
import { AddChangeLog, GetAuditCaseDocument, GetAuditChangeLog, GetAuditDetails, GetClosedCasesList, MapAuditError, PerformAudit } from "../../../../application/action/auditAction";
import { ErrorCategoryList } from "../../../../application/action/errorAction";
import { GetAllUsers } from "../../../../application/action/userAction";
import { MapAuditFormErrorList } from "../../../../application/action/projectAction";
import { format } from "date-fns";
import LoadingBar from 'react-top-loading-bar';
import SupAuditScoreDetail from "./sup-audit-score-details";
import Environment from "../../../../infrastructure/core/Environment";
import AuditScoreDetail from "../../AuditDetail/pages/AuditScore/audit-score-detail";

export default function AuditDetail() {
    const [showChangeLog, setShowChangeLog] = useState(false);
    const [auditorComments, setAuditorComments] = useState('');
    const [IsAccepted, setIsAccepted] = useState(true);
    const [supervisorComments, setSupervisorComments] = useState(false);
    const [markedErrors, setMarkedErrors] = useState([]);

    const handleChangeLog = (state) => {
        setShowChangeLog(state);
    }

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { auditId } = useParams();
    const { auth } = useAuth();

    //const UsersList = useSelector(getUserList)
    const AuditDetailData = useSelector(getAuditDetails)
    const ErrorsList = useSelector(getMapAuditFormErrorsList) || [];
    const MappedErrors = useSelector(getAuditErrors) || [];

    // HANDLE ERROR CHANGES FUNCTION
    const handleErrorChanges = (data) => {
        setMarkedErrors(data)
    };

    // USEEFFECT
    useEffect(() => {
        if (auditId !== undefined) {
            dispatch(GetAuditDetails(auditId))
            dispatch(GetAuditChangeLog(auditId))
            dispatch(ErrorCategoryList())
            dispatch(GetAllUsers())
            dispatch(GetAuditCaseDocument(auditId))
        }
    }, [dispatch, auditId])



    // USEEFFECT
    useEffect(() => {
        if (AuditDetailData?.formId !== undefined) {
            dispatch(MapAuditFormErrorList(AuditDetailData?.formId))
            setSupervisorComments(AuditDetailData?.overAllRebuttal)

        }
    }, [AuditDetailData])

    // Utility functions to calculate points
    const calculatePoints = (ErrorsList, markedErrors, condition) => {
        return ErrorsList?.flatMap(category =>
            category.subErrorCategory.flatMap(sub =>
                sub.errorType.filter(error => condition(markedErrors, error))
            )
        );
    };

    const calculateTotalPoints = errors => {
        return errors.reduce((acc, error) => acc + error.points, 0);
    };

    // Conditions for different types of errors
    const isRemainingError = (markedErrors, error) =>
        markedErrors?.some(mapped => mapped.errorId === error.id);
    const isScoredError = (markedErrors, error) =>
        markedErrors?.some(mapped => mapped.errorId === error.id && !mapped.met);

    const isRebuttalError = (markedErrors, error) =>
        markedErrors?.some(mapped =>
            mapped.errorId === error.id && !mapped.met && mapped.supervisorRebuttal
        );
    // Calculation logic
    const remainingErrors = calculatePoints(ErrorsList, markedErrors, isRemainingError);
    const scoredErrors = calculatePoints(ErrorsList, markedErrors, isScoredError);
    const rebuttalErrors = calculatePoints(ErrorsList, markedErrors, isRebuttalError);

    const totalPoints = calculateTotalPoints(remainingErrors);
    const scoredPoints = calculateTotalPoints(scoredErrors);
    const rebuttalPoints = calculateTotalPoints(rebuttalErrors);

    const achievedPoints = totalPoints - scoredPoints;
    const rebuttalAchievedPoints = achievedPoints + rebuttalPoints;
    // Calculate percentage
    const percentage = totalPoints > 0
        ? ((achievedPoints / totalPoints) * 100).toFixed(2)
        : 100; // Handle division by zero
    // Chart data and options
    const data = {
        labels: ['Good Points', 'Bad Points'],
        datasets: [
            {
                // data: [totalPoints, Math.abs(scoredPoints)],
                // backgroundColor: ['#098911', '#e85353'], // Green for good, red for bad
                data: percentage > 0 ? [achievedPoints, scoredPoints] : [0, 1], // Ensure valid data structure
                backgroundColor: percentage > 0 ? ['#098911', '#e85353'] : ['#e85353', '#e85353'],
                borderWidth: 0,
            },
        ],
    };

    const options = {
        cutout: '80%',
        rotation: -90,
        circumference: 180,
        responsive: true,
        plugins: {
            tooltip: { enabled: false },
            legend: { display: false },
        },
    };


    // HANLDE SUBMIT FUNCTION
    const handleSubmiATA = () => {
        handleSubmitError()
        const auditData = {
            id: auditId,
            formId: AuditDetailData.formId,
            projectId: AuditDetailData.projectId,
            caseId: AuditDetailData.caseId,
            datasetName: AuditDetailData.projectName.replace(/ /g, "_"),
            agentId: AuditDetailData.agentId,
            supervisorId: AuditDetailData.supervisorId,
            expectedScore: AuditDetailData.expectedScore,
            finalScore: AuditDetailData.finalScore,
            auditScore: AuditDetailData.auditorScore,
            ataScore: achievedPoints,
            rebuttalScore: 0,
            overAllRebuttal: supervisorComments,
            overAllAuditorComment: AuditDetailData.overAllComment,
            assignedTo: AuditDetailData.auditedBy,
            status: "2061156f-e154-4b21-80e6-641677224505",
            auditedBy: AuditDetailData.auditedBy,
            ataBy: auth.id,
            isATADone: true,
            lmsCaseId: AuditDetailData.lmsCaseId
        };
        dispatch(PerformAudit(auditData))
        navigate('/supervisorAudits/manage')
        dispatch(GetClosedCasesList(auth.id))
    };
    const handleSubmit = () => {
        const auditData = {
            id: auditId,
            formId: AuditDetailData.formId,
            projectId: AuditDetailData.projectId,
            caseId: AuditDetailData.caseId,
            datasetName: AuditDetailData.projectName.replace(/ /g, "_"),
            agentId: AuditDetailData.agentId,
            supervisorId: AuditDetailData.supervisorId,
            expectedScore: AuditDetailData.expectedScore,
            finalScore: AuditDetailData.finalScore,
            auditScore: AuditDetailData.auditorScore,
            ataScore: rebuttalAchievedPoints,
            rebuttalScore: rebuttalAchievedPoints,
            overAllRebuttal: supervisorComments,
            overAllAuditorComment: AuditDetailData.overAllComment,
            assignedTo: AuditDetailData.auditedBy,
            status: "2061156f-e154-4b21-80e6-641677224505",
            auditedBy: AuditDetailData.auditedBy,
            ataBy: auth.id,
            isATADone: false,
            lmsCaseId: AuditDetailData.lmsCaseId
        };
        dispatch(PerformAudit(auditData))
        navigate('/supervisorAudits/manage')
        dispatch(GetClosedCasesList(auth.id))
        const changeData = {
            "mappingId": Environment.defaultValue,
            "auditId": auditId,
            "summary": `Case Sent for Review Rebuttal with final comment "${supervisorComments}"`,
            "updatedBy": auth.id,
            "updatedByName": auth.user,
        }
        dispatch(AddChangeLog(changeData))
    };

    // HANDLE ACCEPT FUNCTION
    const handleAccept = () => {
        const auditData = {
            id: auditId,
            formId: AuditDetailData.formId,
            projectId: AuditDetailData.projectId,
            caseId: AuditDetailData.caseId,
            datasetName: AuditDetailData.projectName.replace(/ /g, "_"),
            agentId: AuditDetailData.agentId,
            supervisorId: AuditDetailData.supervisorId,
            expectedScore: AuditDetailData.expectedScore,
            finalScore: AuditDetailData.finalScore,
            auditScore: AuditDetailData.auditorScore,
            ataScore: 0,
            rebuttalScore: 0,
            overAllRebuttal: '-',
            overAllAuditorComment: AuditDetailData.overAllComment,
            assignedTo: AuditDetailData.assignedTo,
            status: "2061156f-e154-4b21-80e6-641677224506",
            auditedBy: AuditDetailData.createdBy,
            ataBy: auth.id,
            isATADone: true,
            lmsCaseId: AuditDetailData.lmsCaseId
        };
        dispatch(PerformAudit(auditData))
        navigate('/supervisorAudits/manage')
        dispatch(GetClosedCasesList(auth.id))
        const changeData = {
            "mappingId": Environment.defaultValue,
            "auditId": auditId,
            "summary": `Accepted the Score and Closed`,
            "updatedBy": auth.id,
            "updatedByName": auth.user,
        }
        dispatch(AddChangeLog(changeData))
    };

    // HANDLE REJECT FUNCTION
    const handleReject = () => {
        setIsAccepted(false)
    };

    // PROGRESS TOP LOADER
    const loadingBarRef = useRef(null);
    useEffect(() => {
        if (loadingBarRef.current) {
            // Start the loading bar
            loadingBarRef.current.continuousStart();

            // Simulate a load completion or manage dynamically
            const timer = setTimeout(() => {
                loadingBarRef.current.complete();
            }, 3000); // Adjust timing as needed

            // Cleanup timer
            return () => clearTimeout(timer);
        };
    }, []);


    // HANDLE SUBMIT
    const handleSubmitError = () => {
        const formattedData = markedErrors
            ?.filter(data => (data.met && data.mappingId) || (!data.met && !data.mappingId))
            .map(data => ({
                id: data.mappingId || Environment.defaultValue,
                auditorComment: data.remark, // Include remark
                supervisorRebuttal: data.supervisorRebuttal,
                errorId: data.errorId,
                auditId: auditId,
                updatedBy: auth.id,
                addedBy: 'supervisor',
                isRebutAccepted: data.accept || Environment.defaultValue,
            }));

        if (formattedData.length !== 0) {
            dispatch(MapAuditError(formattedData)); // Dispatch audit updates in bulk
        }
        // Create change logs for each error
        formattedData.forEach(data => {
            const findErrorDetails = (errorId) => {
                for (const category of ErrorsList) {
                    for (const subCategory of category.subErrorCategory) {
                        const matchingError = subCategory.errorType.find(error => error.id === errorId);
                        if (matchingError) {
                            return {
                                errorName: matchingError.errorName,
                                errorCategoryName: category.errorCategoryName,
                            };
                        }
                    }
                }
                return null; // Return null if no match found
            };

            const errorDetails = findErrorDetails(data.errorId);

            if (errorDetails) {
                const changeData = {
                    mappingId: Environment.defaultValue,
                    auditId: auditId,
                    summary: `Added Error: ${errorDetails?.errorName} (${errorDetails?.errorCategoryName}) with comment "${data.supervisorRebuttal}"`,
                    updatedBy: auth.id,
                    updatedByName: auth.user,
                };

                dispatch(AddChangeLog(changeData)); // Dispatch change log for each error
            } else {
                console.warn(`No details found for errorId: ${data.errorId}`);
            }
        });
    };

    return (
        <React.Fragment>
            <Header />
            <ChangeLogModal show={showChangeLog} closeFunction={handleChangeLog} />
            <LoadingBar color="#506fd9" ref={loadingBarRef} shadow={true} />
            <div className="main manage-item-page audit-detail-page ps-0 pe-0" style={{ paddingTop: "70px", backgroundColor: "#F6F8FA" }}>
                <div className="page-top-header d-md-flex align-items-center justify-content-between mb-4">
                    <div className="d-flex gap-2 align-items-center">
                        <Link><i className="ri-arrow-left-s-line fs-22"></i></Link>
                        <h3 className="mb-0 fw-semibold fs-18">{
                            AuditDetailData?.displayColumns?.find(col => col.colName === 'LIMS_CASE_ID')?.colValue || 'N/A'
                        }<Badge className="align-middle" bg="warning" pill>Open</Badge></h3>
                    </div>
                    <div className="d-flex gap-2 top-header-bttns">

                        {IsAccepted && AuditDetailData?.status !=="2061156f-e154-4b21-80e6-641677224504" && (
                            <>
                                <Button variant="success" className="text-uppercase" onClick={handleAccept}>ACCEPT AUDIT</Button>
                                <Button variant="danger" className="text-uppercase" onClick={handleReject}>REJECT & REBUTTAL</Button></>
                        )}
                        {!IsAccepted && AuditDetailData?.status !== "2061156f-e154-4b21-80e6-641677224504" && (
                            <Button variant="primary" className="text-uppercase" onClick={handleSubmit}>Submit Audit</Button>
                        )}
                        {AuditDetailData?.status === "2061156f-e154-4b21-80e6-641677224504" && (
                            <Button variant="primary" className="text-uppercase" onClick={handleSubmiATA}>Submit ATA</Button>
                        )}
                        {/* <Button variant="primary" className="text-uppercase">PERFORM ATA</Button> */}
                    </div>
                </div>


                <Row className="m-0">
                    {/* LEFT AREA */}
                    <Col xl={3} lg={4}>
                        <Card className="card-one audit-details-card">
                            <Card.Body className="overflow-y custom-scroll">
                                <div>
                                    {/* DOUGHNUT CHART VIEW */}
                                    <div>
                                        <div className="doughnut-chart-view" style={{ position: 'relative', width: '100%' }}>
                                            <Doughnut data={data} options={options} />
                                            <div className="doughnut-chart-value">
                                                <div>Overall Score</div>
                                                <div>{percentage} %</div>
                                            </div>
                                        </div>
                                    </div>
                                    {/*  */}
                                    <div className="border d-flex align-items-center justify-content-between p-3 mb-2">
                                        <div>
                                            <h3 className="fs-16">Audit Score</h3>
                                        </div>
                                        <div>
                                            <b className="fs-16">{AuditDetailData?.finalScore}/{AuditDetailData?.expectedScore}</b>
                                        </div>
                                    </div>

                                    <div className="border d-flex align-items-center justify-content-between p-3 mb-3">
                                        <div>
                                            <h3 className="fs-16">ATA Score</h3>
                                        </div>
                                        <div>
                                            <h3 className="fs-16">{AuditDetailData?.isATADone ? AuditDetailData?.ATAScore : 'Not Performed'}</h3>
                                        </div>
                                    </div>
                                </div>

                                {/* CASE DATA */}
                                <div className="mb-4">
                                    <CaseData />
                                </div>

                                {/* UPLOAD DOCUMENT  */}
                                <div>
                                    <UploadedDocument />
                                </div>
                            </Card.Body>
                        </Card>
                    </Col>

                    {/* RIGHT AREA */}
                    <Col xl={9} lg={8}>
                        <Card className="card-one h-auto mb-3">
                            <Card.Body>
                                <Row className="g-3">
                                    <Col xl={2} md={4} xs={6}>
                                        <h6 className="fs-14 fw-bold mb-1">Rebuttal</h6>
                                        <Form.Label className="mb-0">
                                            <Badge bg="primary" pill>Yes</Badge>
                                        </Form.Label>
                                    </Col>
                                    <Col xl md={4} xs={6}>
                                        <h6 className="fs-14 fw-bold mb-1">{AuditDetailData?.auditorName}</h6>
                                        <Form.Label className="mb-0">Auditor Name</Form.Label>
                                    </Col>
                                    <Col xl md={4} xs={6}>
                                        <h6 className="fs-14 fw-bold mb-1">{
                                            AuditDetailData?.displayColumns?.find(col => col.colName === 'EMPLOYEE_NAME')?.colValue || 'N/A'
                                        }</h6>
                                        <Form.Label className="mb-0">Employee Name</Form.Label>
                                    </Col>
                                    <Col xl md={4} xs={6}>
                                        <h6 className="fs-14 fw-bold mb-1">{
                                            AuditDetailData?.displayColumns?.find(col => col.colName === 'LIMS_CASE_ID')?.colValue || 'N/A'
                                        }</h6>
                                        <Form.Label className="mb-0">LMIS Case ID</Form.Label>
                                    </Col>
                                    <Col xl md={4} xs={6}>
                                        {AuditDetailData && (<h6 className="fs-14 fw-bold mb-1">{format(new Date(AuditDetailData?.createdOn), "dd MMM yyyy hh:mm a")}</h6>)}
                                        <Form.Label className="mb-0">Action Date</Form.Label>
                                    </Col>
                                </Row>
                            </Card.Body>
                        </Card>
                        <Tab.Container id="left-tabs-example" defaultActiveKey="audit_score_tab">
                            <Card className="card-one audit-details-tabs-card">
                                <Card.Header className="p-0 d-block">
                                    <Nav className="m-0" variant="pills">
                                        <Nav.Item>
                                            <Nav.Link eventKey="audit_score_tab">
                                                <div className="d-flex align-items-center justify-content-between d-sm-block">
                                                    <div>Auditor Score</div>
                                                    <div className="text-right text-sm-start">
                                                        <h2 className="fs-16 fw-bold">{AuditDetailData?.finalScore}/{AuditDetailData?.expectedScore}</h2>
                                                        <h4 className="fs-12">Points</h4>
                                                    </div>
                                                </div>
                                            </Nav.Link>
                                        </Nav.Item>
                                        {(AuditDetailData?.status === "2061156f-e154-4b21-80e6-641677224504") && (
                                            <Nav.Item>
                                                <Nav.Link eventKey="ata_score_tab">
                                                    <div className="d-flex align-items-center justify-content-between d-sm-block">
                                                        <div>ATA Score</div>
                                                        <div className="text-right text-sm-start">
                                                            <h2 className="fs-16 fw-bold">{AuditDetailData?.ataScore}/{AuditDetailData?.expectedScore}</h2>
                                                            <h4 className="fs-12">Points</h4>
                                                        </div>
                                                    </div>
                                                </Nav.Link>
                                            </Nav.Item>
                                        )}

                                    </Nav>

                                    <div className="position-absolute end-10 z-index-2 top-10 change-log-btn">
                                        <Button variant="primary" onClick={() => handleChangeLog(true)}>Change Log</Button>
                                    </div>
                                </Card.Header>
                                <Card.Body className="overflow-y custom-scroll p-0" style={{ backgroundColor: "#f5f7f9" }}>
                                    <Tab.Content>
                                        {/* AUDIT SCORE TAB */}
                                        <Tab.Pane eventKey="audit_score_tab">
                                            <Card className="card-one audit-details-tabs-card">
                                                <Card.Body className="overflow-y custom-scroll p-0" style={{ backgroundColor: "#f5f7f9" }}>
                                                    <SupAuditScoreDetail IsAccepted={IsAccepted} ErrorChanges={handleErrorChanges} setAuditorComments={setAuditorComments} setSupervisorComments={setSupervisorComments} />
                                                </Card.Body>
                                            </Card>
                                            {/* <div className="mt-3">
                                                <AuditScore  ErrorChanges={handleErrorChanges} setAuditorComments={setAuditorComments} setSupervisorComments={setSupervisorComments}  />
                                            </div> */}
                                        </Tab.Pane>
                                        {/* ATA SCORE TAB */}
                                        <Tab.Pane eventKey="ata_score_tab">
                                            <div className="mt-3">
                                                <ATAScore ErrorChanges={handleErrorChanges} setAuditorComments={setAuditorComments} setSupervisorComments={setSupervisorComments} />
                                                {/* <AuditScore ErrorChanges={handleErrorChanges} setAuditorComments={setAuditorComments} setSupervisorComments={setSupervisorComments} /> */}
                                            </div>
                                        </Tab.Pane>
                                    </Tab.Content>
                                </Card.Body>
                            </Card>
                        </Tab.Container>
                    </Col>
                </Row>
                {/* )}
                {loading && value === "itemList" && items?.result === undefined && (
                  <CardShimmerLoader columnCount={4} rowCount={4} />
                )} */}
            </div>
        </React.Fragment>
    )
}