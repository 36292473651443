import { Navigate } from "react-router-dom";
import * as Loading from "../action/loaderAction";
import { GET_ERROR_LIST, ErrorListSuccess, GET_ERROR_CATEGORY_LIST, ErrorCategoryListSuccess, ERROR_UPLOAD, ERROR_CATEGORY_UPLOAD, SubErrorListSuccess, GetPointsListSuccess, SUB_ERROR_UPLOAD, GET_SUB_ERROR_TYPE_LIST, ADD_POINTS, GET_POINT_LIST, DELETE_ERROR, DELETE_ERROR_CATEGORY, DELETE_ERROR_SUBCATEGORY } from "../action/errorAction";
import ToastifyService from "../../view/_common/ToastifyService";


const ErrorList =
  ({ api }) =>
    ({ dispatch }) =>
      (next) =>
        async (action) => {
          if (action.type === GET_ERROR_LIST) {
            try {
              dispatch(Loading.setLoading({ loading: true, value: "errorList" }));
              const errorList = await api.errorAPI.ErrorList(action.payload);

              dispatch(ErrorListSuccess(errorList));
              dispatch(Loading.setLoading({ loading: false, value: "errorList" }));
            } catch (error) {
              ToastifyService.error("Something Went wrong! ");
              console.log(error)
              if (error.response.data === "Invalid access token or refresh token") {
                localStorage.removeItem("Token");
                <Navigate to="/account/login" />;
              }
            }
          }
          next(action);
        };
const ErrorCategoryList =
  ({ api }) =>
    ({ dispatch }) =>
      (next) =>
        async (action) => {
          if (action.type === GET_ERROR_CATEGORY_LIST) {
            try {
              dispatch(Loading.setLoading({ loading: true, value: "errorCategoryList" }));
              const errorCategoryList = await api.errorAPI.ErrorCategoryList(action.payload);

              dispatch(ErrorCategoryListSuccess(errorCategoryList));
              dispatch(Loading.setLoading({ loading: false, value: "errorCategoryList" }));
            } catch (error) {
              ToastifyService.error("Something Went wrong! ");
              console.log(error)
              if (error.response.data === "Invalid access token or refresh token") {
                localStorage.removeItem("Token");
                <Navigate to="/account/login" />;
              }
            }
          }
          next(action);
        };
const SubErrorTypeList =
  ({ api }) =>
    ({ dispatch }) =>
      (next) =>
        async (action) => {
          if (action.type === GET_SUB_ERROR_TYPE_LIST) {
            try {
              dispatch(Loading.setLoading({ loading: true, value: "subErrorList" }));
              const subErrorList = await api.errorAPI.SubErrorTypeList(action.payload);

              dispatch(SubErrorListSuccess(subErrorList));
              dispatch(Loading.setLoading({ loading: false, value: "subErrorList" }));
            } catch (error) {
              ToastifyService.error("Something Went wrong! ");
              console.log(error)
              if (error.response.data === "Invalid access token or refresh token") {
                localStorage.removeItem("Token");
                <Navigate to="/account/login" />;
              }
            }
          }
          next(action);
        };
const DeleteError =
  ({ api }) =>
    ({ dispatch }) =>
      (next) =>
        async (action) => {
          if (action.type === DELETE_ERROR) {
            try {
              dispatch(Loading.setLoading({ loading: true, value: "errorList" }));
              const errorList = await api.errorAPI.DeleteError(action.payload);

              dispatch(ErrorListSuccess(errorList));
              dispatch(Loading.setLoading({ loading: false, value: "errorList" }));
            } catch (error) {
              ToastifyService.error("Something Went wrong! ");
              console.log(error)
              if (error.response.data === "Invalid access token or refresh token") {
                localStorage.removeItem("Token");
                <Navigate to="/account/login" />;
              }
            }
          }
          next(action);
        };

const DeleteErrorCategory =
  ({ api }) =>
    ({ dispatch }) =>
      (next) =>
        async (action) => {
          if (action.type === DELETE_ERROR_CATEGORY) {
            try {
              dispatch(Loading.setLoading({ loading: true, value: "errorCategoryList" }));
              const errorCategoryList = await api.errorAPI.DeleteErrorCategory(action.payload);

              dispatch(ErrorCategoryListSuccess(errorCategoryList));
              dispatch(Loading.setLoading({ loading: false, value: "errorCategoryList" }));
            } catch (error) {
              ToastifyService.error("Something Went wrong! ");
              console.log(error)
              if (error.response.data === "Invalid access token or refresh token") {
                localStorage.removeItem("Token");
                <Navigate to="/account/login" />;
              }
            }
          }
          next(action);
        };
const DeleteErrorSubCategory =
  ({ api }) =>
    ({ dispatch }) =>
      (next) =>
        async (action) => {
          if (action.type === DELETE_ERROR_SUBCATEGORY) {
            try {
              dispatch(Loading.setLoading({ loading: true, value: "subErrorList" }));
              const subErrorList = await api.errorAPI.DeleteErrorSubCategory(action.payload);

              dispatch(SubErrorListSuccess(subErrorList));
              dispatch(Loading.setLoading({ loading: false, value: "subErrorList" }));
            } catch (error) {
              ToastifyService.error("Something Went wrong! ");
              console.log(error)
              if (error.response.data === "Invalid access token or refresh token") {
                localStorage.removeItem("Token");
                <Navigate to="/account/login" />;
              }
            }
          }
          next(action);
        };
const UploadError =
  ({ api }) =>
    ({ dispatch }) =>
      (next) =>
        async (action) => {
          if (action.type === ERROR_UPLOAD) {
            try {
              dispatch(Loading.setLoading({ loading: true, value: "errorList" }));

              const errorList = await api.errorAPI.UploadError(action.payload);
              dispatch(ErrorListSuccess(errorList));
              dispatch(Loading.setLoading({ loading: false, value: "errorList" }));
              ToastifyService.success("Error Types Uploaded Successfully! ");
            } catch (error) {
              ToastifyService.error("Something Went wrong! ");
              console.log(error)
              if (error.response.data === "Invalid access token or refresh token") {
                localStorage.removeItem("Token");
                <Navigate to="/account/login" />;
              }
            }
          }
          next(action);
        };

const UploadErrorCategory =
  ({ api }) =>
    ({ dispatch }) =>
      (next) =>
        async (action) => {
          if (action.type === ERROR_CATEGORY_UPLOAD) {
            try {
              dispatch(Loading.setLoading({ loading: true, value: "errorCategoryList" }));
              const errorCategoryList = await api.errorAPI.UploadErrorCategory(action.payload);

              dispatch(ErrorCategoryListSuccess(errorCategoryList));
              dispatch(Loading.setLoading({ loading: false, value: "errorCategoryList" }));
              ToastifyService.success("Error Category Uploaded Successfully! ");
            } catch (error) {
              ToastifyService.error("Something Went wrong! ");
              console.log(error)
              if (error.response.data === "Invalid access token or refresh token") {
                localStorage.removeItem("Token");
                <Navigate to="/account/login" />;
              }
            }
          }
          next(action);
        };
const UploadSubErrorType =
  ({ api }) =>
    ({ dispatch }) =>
      (next) =>
        async (action) => {
          if (action.type === SUB_ERROR_UPLOAD) {
            try {
              dispatch(Loading.setLoading({ loading: true, value: "subErrorList" }));
              const subErrorList = await api.errorAPI.UploadSubErrorType(action.payload);

              dispatch(SubErrorListSuccess(subErrorList));
              dispatch(Loading.setLoading({ loading: false, value: "subErrorList" }));
              ToastifyService.success("Error Sub Category Uploaded Successfully! ");
            } catch (error) {
              ToastifyService.error("Something Went wrong! ");
              console.log(error)
              if (error.response.data === "Invalid access token or refresh token") {
                localStorage.removeItem("Token");
                <Navigate to="/account/login" />;
              }
            }
          }
          next(action);
        };
const AddPoints =
  ({ api }) =>
    ({ dispatch }) =>
      (next) =>
        async (action) => {
          if (action.type === ADD_POINTS) {
            try {
              dispatch(Loading.setLoading({ loading: true, value: "pointsList" }));
              const pointsList = await api.errorAPI.AddPoints(action.payload);

              dispatch(GetPointsListSuccess(pointsList));
              dispatch(Loading.setLoading({ loading: false, value: "pointsList" }));
              ToastifyService.success("Point Added Successfully! ");
            } catch (error) {
              ToastifyService.error("Something Went wrong! ");
              console.log(error)
              if (error.response.data === "Invalid access token or refresh token") {
                localStorage.removeItem("Token");
                <Navigate to="/account/login" />;
              }
            }
          }
          next(action);
        };
const GetPointsList =
  ({ api }) =>
    ({ dispatch }) =>
      (next) =>
        async (action) => {
          if (action.type === GET_POINT_LIST) {
            try {
              dispatch(Loading.setLoading({ loading: true, value: "pointsList" }));
              const pointsList = await api.errorAPI.GetPointsList(action.payload);

              dispatch(GetPointsListSuccess(pointsList));
              dispatch(Loading.setLoading({ loading: false, value: "pointsList" }));
            } catch (error) {
              ToastifyService.error("Something Went wrong! ");
              console.log(error)
              if (error.response.data === "Invalid access token or refresh token") {
                localStorage.removeItem("Token");
                <Navigate to="/account/login" />;
              }
            }
          }
          next(action);
        };

export default [
  ErrorList, ErrorCategoryList, UploadError, UploadErrorCategory, UploadSubErrorType, SubErrorTypeList, AddPoints, GetPointsList, DeleteError, DeleteErrorCategory, DeleteErrorSubCategory
]