import React, { useEffect, useState } from 'react'
import { Button, Form, Offcanvas } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { getAuditFormListByProjId, getAuditId, getOngoingCasesList, getPendingCasesList } from '../../../../../application/selector/indexSelector'
import Environment from '../../../../../infrastructure/core/Environment'
import useAuth from '../../../../../hooks/useAuth'
import { AddChangeLog, GetPendingCasesList, PerformAudit, UpdateCaseStatus } from '../../../../../application/action/auditAction'
import { useNavigate } from 'react-router-dom'
import jwtInterceptor from '../../../../../infrastructure/core/helpers/jwtInterceptor'

function FormModal(props) {
    const dispatch = useDispatch();
    const { auth } = useAuth();
    const navigate = useNavigate();
    const AuditId = useSelector(getAuditId);
    const FormList = useSelector(getAuditFormListByProjId)
    const [selectedAuditId, setSelectedAuditId] = useState('');
    const PendingCasesList = useSelector(getPendingCasesList);
    const OngoingCasesList = useSelector(getOngoingCasesList);
    const [selectedform, setSelectedform] = useState('')
    useEffect(() => {
        if (props?.selectedProject?.dataSetId) {
            dispatch(GetPendingCasesList(props?.selectedProject?.dataSetId));
        }
    }, [props?.selectedProject])
    const handleStart = async () => {
        if (PendingCasesList?.length > 0 && props?.selectedProject) {
            const [caseToAudit] = PendingCasesList.filter(
                pendingCase => !OngoingCasesList.some(ongoingCase => ongoingCase.caseId === pendingCase.id) && pendingCase.status !== "2061156f-e154-4b21-80e6-641677224502"
            );
            if (caseToAudit) {
                const auditData = {
                    id: Environment.defaultValue,
                    formId: selectedform,
                    projectId: props?.selectedProject.projectId,
                    caseId: caseToAudit.id,
                    datasetName: props?.selectedProject.dataSetName,
                    agentId: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
                    supervisorId: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
                    finalScore: 0,
                    auditScore: 0,
                    ataScore: 0,
                    rebuttalScore: 0,
                    overAllRebuttal: "",
                    overAllAuditorComment: "",
                    assignedTo: auth.id,
                    status: "2061156f-e154-4b21-80e6-641677224502",
                    auditedBy: auth.id,
                    ataBy: Environment.defaultValue,
                    lmsCaseId:caseToAudit.lmsCaseId
                };
                try {

                    const response = await jwtInterceptor.post(`${Environment.AUDIT_URL}createAudit`, auditData, {
                        method: "POST",
                        headers: {
                            "Access-Control-Allow-Origin": "*",
                            "Content-Type": "application/json",
                            //   authorization: `Bearer ${storedToken}`,
                        },
                    }
                    );
        
                    setSelectedAuditId(response.data)
                    navigate(`/create/audit/${response.data}`);
                    const changeData = {
                        "mappingId": Environment.defaultValue,
                        "auditId": response.data,
                        "summary": `Opened Case`,
                        "updatedBy": auth.id,
                        "updatedByName": auth.user,
                    }
                    dispatch(AddChangeLog(changeData))
        
                } catch (err) {
                    console.log(err);
        
                } finally {
                    if (selectedAuditId) {
                        navigate(`/create/audit/${selectedAuditId}`);
                    }
                }
            }}
    }
    // // Navigate to audit details page when AuditId is set
    // useEffect(() => {
    //     if (AuditId && AuditId !== Environment.defaultValue) {
    //         navigate(`/audit/details/${AuditId}`);
    //     }
    // }, [AuditId, navigate]);

    return (
        <React.Fragment>
            <Offcanvas show={props.show} onHide={props.closeFunction} placement="end" className="w-30">
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title className="fs-16 text-dark">Select Form</Offcanvas.Title>
                </Offcanvas.Header>

                <Offcanvas.Body>
                    <div className="add-error-list" >
                        <Form.Select value={selectedform} onChange={(e) => setSelectedform(e.target.value)}>
                            <option value={Environment.defaultValue}>Choose Form </option>
                            {FormList?.map((data) => (
                                <option value={data.formId}>{data.formName}</option>
                            ))}
                        </Form.Select>
                    </div>
                </Offcanvas.Body>

                <div className="offcanvas-footer justify-content-start">
                    <Button type="button" variant="primary" className="fs-14 me-2 d-flex align-items-center" onClick={handleStart}>
                        <span className="align-middle">Start</span>
                    </Button>
                </div>
            </Offcanvas>
        </React.Fragment>

    )
}

export default FormModal