import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getSupervisorList } from "../../../../application/selector/indexSelector";
import ToastifyService from "../../../_common/ToastifyService";
import SweetAlertService from "../../../_common/SweetAlertService";
import { Button, Card, Form } from "react-bootstrap";
import { format } from "date-fns";
import { Link } from "react-router-dom";
import DataTable from "react-data-table-component";
import { DeleteUser, GetAllSupervisor } from "../../../../application/action/userAction";
import UpdateUser from "../pages/update-user";

export default function Supervisor() {
  const dispatch = useDispatch();
  const [error, setError] = useState(null);
  const SupervisorList = useSelector(getSupervisorList);
  const [showUpdateUser, setShowUpdateUser] = useState(false);
  const [editRowData, setEditRowData] = useState({});
  useEffect(() => {
    dispatch(GetAllSupervisor(3));
  }, []);
// HANDLE EDIT FUNCTION
const handleEdit = (state ,row) => {
  setEditRowData(row);
  setShowUpdateUser(state);
};
  // HANDLE DELETE FUNCTION
  const [data, setData] = useState([]);
  const handleDelete = (row) => {
    SweetAlertService.showAlert(
      "Supervisor",
      "Are you sure you want to delete this Supervisor?",
      "warning"
    )
      .then((result) => {
        if (result.isConfirmed) {

        dispatch(DeleteUser(row.empId))
        }
    })
    .catch((error) => {
        console.error("Error:", error);
        ToastifyService.error(`Something went wrong`);
    });
  };

  const columns = [
    {
      name: "ID",
      selector: (row) => <div>{row.empId}</div>,
      width: "50px",
    },
    {
      name: "Supervisor Name",
      selector: (row) => (
        <div>
          <h6 className="mb-0 fw-semibold">
            <Link>{row.emp_fullname}</Link>
          </h6>
          <span className="fs-xs text-secondary">{row.email}</span>
        </div>
      ),
    },
    {
      name: "UserName",
      selector: (row) => row.emp_username,
    },
    {
      name: "Team",
      selector: (row) => row.teamName,
    },
    {
      name: "Modified On",
      selector: (row) =>
        format(new Date(row.modifiedOn), "dd MMM yyyy hh:mm a"),
    },
    {
      name: "Action",
      selector: (row) => (
        <div className="d-flex justify-content-end">
          <Button
            variant="primary"
            className="btn-icon me-2"
            onClick={() => handleEdit(true, row)}
          >
            <i className="ri-pencil-line"></i>
          </Button>
          <Button
            variant="outline-danger"
            className="btn-icon"
            onClick={() => handleDelete(true)}
          >
            <i className="ri-delete-bin-line"></i>
          </Button>
        </div>
      ),
      width: "200px",
    },
  ];
  const [searchTerm, setSearchTerm] = useState('');
  const filteredSupervisorList = SupervisorList?.filter((item) =>
      Object.values(item).some((value) =>
          value && value.toString().toLowerCase().includes(searchTerm.toLowerCase())
      )
  );
  return (
    <React.Fragment>
      <UpdateUser
                show={showUpdateUser}
                closeFunction={() => setShowUpdateUser(false)}
                rowData={editRowData}
                columns={columns}
            />
      <div className="d-md-flex align-items-center justify-content-between mb-4">
        <div>
          <h4 className="fs-16">Supervisor</h4>
        </div>
        <div>
        <div className="custom-drop-down z-index-2 wt-300">
                        {/* <span className=" d-flex dropdown-icon bg-white"><i className="ri-search-line"></i></span> */}
                        <Form.Control type="text" placeholder="Search..." value={searchTerm} onChange={(e) => setSearchTerm(e.target.value)} />
                    </div>
        </div>
      </div>
      <Card className="card-one">
        <Card.Body>
          {/* {error && <p className="text-center" style={{ color: "red" }}>{error}</p>} */}
          {filteredSupervisorList?.length > 0 ? (
            <DataTable
              columns={columns}
              data={filteredSupervisorList}
              pagination
              highlightOnHover
            />
          ) : (
            !error && <p className="text-center">No records to display</p>
          )}
        </Card.Body>
      </Card>
    </React.Fragment>
  );
}
