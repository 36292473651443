import React, { useEffect, useRef, useState } from "react";
import Header from "../../layouts/Header";
//import auditFormData from "../../Json/AuditForm/audit-form.json";
import { Button, Card, Form } from "react-bootstrap";
import DataTable from "react-data-table-component";
import ToastifyService from "../_common/ToastifyService";
import SweetAlertService from "../_common/SweetAlertService";
import CreateAuditForm from "./create-audit-form";
import UpdateAuditForm from "./update-audit-form";
import { useDispatch, useSelector } from "react-redux";
import { getAuditFormList, getLoading } from "../../application/selector/indexSelector";
import TableShimmerLoader from "../_common/ShimmerTable";
import { DeleteAuditForm, GetAuditFormList, GetProjectList } from "../../application/action/projectAction";
import { GetAllHeaders } from "../../application/action/inventoryAction";
import { ErrorCategoryList, ErrorList, GetPointsList, SubErrorTypeList } from "../../application/action/errorAction";
import LoadingBar from 'react-top-loading-bar';
import { format } from "date-fns";

export default function AuditForm() {
    const dispatch = useDispatch();
    const { loading, value } = useSelector(getLoading);

    const AuditFormsList = useSelector(getAuditFormList);

    useEffect(()=>{
        dispatch(GetAuditFormList());
        dispatch(GetProjectList());
        dispatch(GetAllHeaders());
        dispatch(ErrorList());
        dispatch(ErrorCategoryList());
        dispatch(SubErrorTypeList());
        dispatch(GetPointsList());
    },[]);

    const columns = [
        // {
        //     name: "Project Name",
        //     selector: (row) => row.project_name,
        //     sortable: true,
        // },

        {
            name: "Audit Form Name",
            selector: (row) => row.formName,
            sortable: true,
        },

        {
            name: "Description",
            selector: (row) => row.formDescription,
            sortable: true,
        },
        {
            name: "Created On",
            selector: (row) => (
                <div>
                    <h6 className="mb-0 ">{format(new Date(row.modifiedOn), "dd MMM yyyy hh:mm a")}</h6>
                </div>
            ),
            sortable: true, // Enable sorting for this column
        },
        {
            name: "Action",
            selector: (row) => (
                <div className="d-flex">
                    <Button variant="primary" className="btn-icon me-2" onClick={()=> hanldeUpdateAuditForm(true, row )}><i className="ri-pencil-line"></i></Button>
                    <Button variant="outline-danger" className="btn-icon" onClick={() => handleDelete(true, row.formId)}><i className="ri-delete-bin-line"></i></Button>
                </div>
            ),
            width: "200px"
        }

    ];

      // SEARCH FILTER TABLE DATA
      const [searchTerm, setSearchTerm] = useState('');
      const filteredAuditForm = AuditFormsList?.filter((item) =>
          Object.values(item).some((value) =>
              value && value.toString().toLowerCase().includes(searchTerm.toLowerCase())
          )
      );
  
    // HANDLE DELETE FUNCTION
    const [data, setData] = useState([]);
    const handleDelete = (index, id) => {
        SweetAlertService.showAlert(
            "Audit Form",
            "Are you sure you want to delete this Audit Form?",
            "warning"
        )
            .then((result) => {
                if (result.isConfirmed) {
                    dispatch(DeleteAuditForm(id))
                    ToastifyService.success(`Audit Form Name Deleted Successfully`);
                    const newData = [...data];
                    newData.splice(index, 1);
                    setData(newData);
                }
            })
            .catch((error) => {
                console.error("Error:", error);
                ToastifyService.error(`Something went wrong`);
            });
    };

    const [showCreateAuditForm, setShowCreateAuditForm] = useState(false);
    const handleCreateAuditForm =(state)=> {
        setShowCreateAuditForm(state);
    };

    const [showUpdateAuditForm, setShowUpdateAuditForm] = useState(false);
    const [selectedData, setSelectedData] = useState(false);
    const hanldeUpdateAuditForm = (state, data)=> {
        setShowUpdateAuditForm(state);
        setSelectedData(data)
    };

    // PROGRESS TOP LOADER
    const loadingBarRef = useRef(null);
    useEffect(() => {
        if (loadingBarRef.current) {
            // Start the loading bar
            loadingBarRef.current.continuousStart();
    
            // Simulate a load completion or manage dynamically
            const timer = setTimeout(() => {
                loadingBarRef.current.complete();
            }, 3000); // Adjust timing as needed
    
            // Cleanup timer
            return () => clearTimeout(timer);
        }
      }, []);


    return (
        <React.Fragment>
            <Header />
            <CreateAuditForm show={showCreateAuditForm} closeFunction={handleCreateAuditForm} />
            <UpdateAuditForm show={showUpdateAuditForm} closeFunction={hanldeUpdateAuditForm} data={selectedData} />
            <LoadingBar color="#506fd9" ref={loadingBarRef} shadow={true} />
            <div className="main main-app p-3 p-lg-4">
                <div className="d-md-flex align-items-center justify-content-between mb-4">
                    <div>
                        <h4 className="main-title mb-000">Audit Form</h4>
                    </div>
                    <div>
                        <Button variant="primary" onClick={()=> handleCreateAuditForm(true)}><i className="ri-add-line align-middle"></i> Create New Audit Form</Button>
                    </div>
                </div>

                <Card className="card-one">
                    <Card.Header className="align-items-center justify-content-between">
                        <h4 className="main-title fs-14 mb-0">List of Audit Form</h4>
                        <div className="custom-dropdown-wrapper">
                            <div className="custom-drop-down z-index-2 wt-300">
                                <span className="dropdown-icon bg-white"><i className="ri-search-line"></i></span>
                                <Form.Control
                                    type="text"
                                    placeholder="Search..."
                                    value={searchTerm}
                                    onChange={(e) => setSearchTerm(e.target.value)}
                                />
                            </div>
                        </div>
                    </Card.Header>
                    <Card.Body>
                    {filteredAuditForm && filteredAuditForm !== undefined && (
                        <DataTable
                            columns={columns}
                            data={filteredAuditForm}
                            fixedHeader
                            search={true}
                            highlightOnHover
                            pagination
                        ></DataTable>
                    )}
                    {!loading && filteredAuditForm === undefined && (
                        <TableShimmerLoader colNames={columns?.map((column) => column.name)} colsCount={1} />
                    )}
                    {loading &&
                        value === "ProjectList" && filteredAuditForm === undefined && (
                            <TableShimmerLoader colNames={columns?.map((column) => column.name)} colsCount={1} />
                        )}
                    </Card.Body>
                </Card>
            </div>
        </React.Fragment>
    )
}