
import Environment from '../../core/Environment';
import jwtInterceptor from '../../core/helpers/jwtInterceptor';

const ProjectList = async () => {
  const storedToken = JSON.parse(localStorage.getItem("Token"));
  
  const response = await jwtInterceptor.get(`${Environment.PROJECT_URL}getProjectList`,{
      method: "GET",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        authorization: `Bearer ${storedToken}`,
      },
    }
  );
  return response;
};
const GetProjectDetails = async (data) => {
  const storedToken = JSON.parse(localStorage.getItem("Token"));
  
  const response = await jwtInterceptor.get(`${Environment.PROJECT_URL}getProjectDetails/${data}`,{
      method: "GET",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        authorization: `Bearer ${storedToken}`,
      },
    }
  );
  return response;
};
const AuditFormList = async () => {
  const storedToken = JSON.parse(localStorage.getItem("Token"));
  
  const response = await jwtInterceptor.get(`${Environment.AUDIT_URL}GetAllAuditForm`,{
      method: "GET",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        authorization: `Bearer ${storedToken}`,
      },
    }
  );
  return response;
};
const GetAuditFormListByProjId = async (data) => {
  const storedToken = JSON.parse(localStorage.getItem("Token"));
  
  const response = await jwtInterceptor.get(`${Environment.AUDIT_URL}GetAuditFormByProjectId/${data}`,{
      method: "GET",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        authorization: `Bearer ${storedToken}`,
      },
    }
  );
  return response;
};
const AddProject  = async (data) => {
    const storedToken = JSON.parse(localStorage.getItem("Token"));
    
    const response = await jwtInterceptor.post(`${Environment.PROJECT_URL}addProject`,data,{
        method: "POST",
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
          authorization: `Bearer ${storedToken}`,
        },
      }
    );
    return response;
  };
const DeleteProject  = async (data) => {
    const storedToken = JSON.parse(localStorage.getItem("Token"));
    const response = await jwtInterceptor.post(`${Environment.PROJECT_URL}deleteProject/${data}`,{
        method: "POST",
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
          authorization: `Bearer ${storedToken}`,
        },
      }
    );
    return response;
  };
const UpdateProject  = async (data) => {
    const storedToken = JSON.parse(localStorage.getItem("Token"));
    
    const response = await jwtInterceptor.post(`${Environment.PROJECT_URL}updateProject`,data,{
        method: "POST",
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
          authorization: `Bearer ${storedToken}`,
        },
      }
    );
    return response;
  };
const AddAuditForm  = async (data) => {
  const storedToken = JSON.parse(localStorage.getItem("Token"));
  const response = await jwtInterceptor.post(`${Environment.AUDIT_URL}createAuditForm`,data,{
      method: "POST",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        authorization: `Bearer ${storedToken}`,
      },
    }
  );
  return response;
};
const DeleteAuditForm  = async (data) => {
  const storedToken = JSON.parse(localStorage.getItem("Token"));
  const response = await jwtInterceptor.post(`${Environment.AUDIT_URL}DeleteAuditForm/${data}`,{
      method: "POST",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        authorization: `Bearer ${storedToken}`,
      },
    }
  );
  return response;
};
const MapProjectAuditor  = async (data) => {
    const storedToken = JSON.parse(localStorage.getItem("Token"));
    
    const response = await jwtInterceptor.post(`${Environment.AUDIT_URL}mapProjectAuditor`,data,{
        method: "POST",
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
          authorization: `Bearer ${storedToken}`,
        },
      }
    );
    return response;
  };
const UnMapProjectAuditor  = async (data) => {
    const storedToken = JSON.parse(localStorage.getItem("Token"));
    
    const response = await jwtInterceptor.post(`${Environment.AUDIT_URL}unMapProjectAuditor/${data}`,{
        method: "POST",
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
          authorization: `Bearer ${storedToken}`,
        },
      }
    );
    return response;
  };
const GetMapProjectAuditorList  = async (data) => {
  const storedToken = JSON.parse(localStorage.getItem("Token"));
  
  const response = await jwtInterceptor.get(`${Environment.AUDIT_URL}GetAllProjectAuditor/${data}`,{
      method: "GET",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        authorization: `Bearer ${storedToken}`,
      },
    }
  );
  return response;
};
const MapProjectAuditForm  = async (data) => {
  const storedToken = JSON.parse(localStorage.getItem("Token"));
  
  const response = await jwtInterceptor.post(`${Environment.PROJECT_URL}mapProjectAuditForm`,data,{
      method: "POST",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        authorization: `Bearer ${storedToken}`,
      },
    }
  );
  return response;
};
const UnMapProjectAuditForm  = async (data) => {
  const storedToken = JSON.parse(localStorage.getItem("Token"));
  
  const response = await jwtInterceptor.post(`${Environment.PROJECT_URL}unMapProjectAuditForm/${data}`,{
      method: "POST",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        authorization: `Bearer ${storedToken}`,
      },
    }
  );
  return response;
};
const GetMapProjectAuditFormList  = async (data) => {
  const storedToken = JSON.parse(localStorage.getItem("Token"));
  
  const response = await jwtInterceptor.post(`${Environment.PROJECT_URL}GetProjecAuditList`,data,{
      method: "POST",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        authorization: `Bearer ${storedToken}`,
      },
    }
  );
  return response;
};
const GetMapProjectAuditFormListById  = async (data) => {
  const storedToken = JSON.parse(localStorage.getItem("Token"));
  
  const response = await jwtInterceptor.get(`${Environment.PROJECT_URL}GetAllAuditFormsByProjId/${data}`,{
      method: "GET",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        authorization: `Bearer ${storedToken}`,
      },
    }
  );
  return response;
};
const MapProjectColumn  = async (data) => {
  const storedToken = JSON.parse(localStorage.getItem("Token"));
  const response = await jwtInterceptor.post(`${Environment.PROJECT_URL}MapProjectColumns`,data,{
      method: "POST",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        authorization: `Bearer ${storedToken}`,
      },
    }
  );
  return response;
};
const UnMapProjectColumn  = async (data) => {
  const storedToken = JSON.parse(localStorage.getItem("Token"));
  const response = await jwtInterceptor.post(`${Environment.PROJECT_URL}UnMapProjectColumns/${data.id}/${data.projectId}`,{
      method: "POST",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        authorization: `Bearer ${storedToken}`,
      },
    }
  );
  return response;
};
const MapProjectColumnList  = async (data) => {
  const storedToken = JSON.parse(localStorage.getItem("Token"));
  
  const response = await jwtInterceptor.get(`${Environment.PROJECT_URL}GetProjectColList/${data}`,{
      method: "GET",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        authorization: `Bearer ${storedToken}`,
      },
    }
  );
  return response;
};

const MapAuditFormColumn  = async (data) => {
  const storedToken = JSON.parse(localStorage.getItem("Token"));
  const response = await jwtInterceptor.post(`${Environment.PROJECT_URL}MapProjectColumns`,data,{
      method: "POST",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        authorization: `Bearer ${storedToken}`,
      },
    }
  );
  return response;
};
const MapAuditFormColumnList  = async (data) => {
  const storedToken = JSON.parse(localStorage.getItem("Token"));
  
  const response = await jwtInterceptor.post(`${Environment.PROJECT_URL}GetProjecAuditList`,data,{
      method: "POST",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        authorization: `Bearer ${storedToken}`,
      },
    }
  );
  return response;
};
const UnMapAuditFormErrors  = async (data) => {
  const storedToken = JSON.parse(localStorage.getItem("Token"));
  const response = await jwtInterceptor.get(`${Environment.AUDIT_URL}unMaplErrorsAuditForm/${data.Id}/${data.formId}`,{
      method: "GET",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        authorization: `Bearer ${storedToken}`,
      },
    }
  );
  return response;
};
const MapAuditFormErrors  = async (data) => {
  const storedToken = JSON.parse(localStorage.getItem("Token"));
  const response = await jwtInterceptor.post(`${Environment.AUDIT_URL}mapErrorCategoryAuditForm`,data,{
      method: "POST",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        authorization: `Bearer ${storedToken}`,
      },
    }
  );
  return response;
};
const UnMapAuditFormErrorType  = async (data) => {
  const storedToken = JSON.parse(localStorage.getItem("Token"));
  const response = await jwtInterceptor.get(`${Environment.AUDIT_URL}unMaplErrorsAuditForm/${data.Id}/${data.formId}`,{
      method: "GET",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        authorization: `Bearer ${storedToken}`,
      },
    }
  );
  return response;
};
const MapAuditFormErrorType  = async (data) => {
  const storedToken = JSON.parse(localStorage.getItem("Token"));
  const response = await jwtInterceptor.post(`${Environment.AUDIT_URL}mapErrorTypeAuditForm`,data,{
      method: "POST",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        authorization: `Bearer ${storedToken}`,
      },
    }
  );
  return response;
};
const MapAuditFormErrorList  = async (data) => {
  const storedToken = JSON.parse(localStorage.getItem("Token"));
  const response = await jwtInterceptor.get(`${Environment.AUDIT_URL}GetAllErrorsAuditForm/${data}`,{
      method: "GET",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        authorization: `Bearer ${storedToken}`,
      },
    }
  );
  return response;
};
const ProjectListByAuditFormId  = async (data) => {
  const storedToken = JSON.parse(localStorage.getItem("Token"));
  
  const response = await jwtInterceptor.get(`${Environment.AUDIT_URL}GetProjectListByAuditFormId/${data}`,{
      method: "GET",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        authorization: `Bearer ${storedToken}`,
      },
    }
  );
  return response;
};
export default {
    ProjectList,AddProject,AuditFormList,MapProjectAuditor,GetMapProjectAuditorList,AddAuditForm,MapProjectAuditForm,MapProjectColumn,
    GetMapProjectAuditFormList,GetAuditFormListByProjId,MapAuditFormErrorType, UnMapAuditFormErrorType,
    MapProjectColumnList,MapAuditFormColumn,MapAuditFormColumnList,GetMapProjectAuditFormListById,MapAuditFormErrors,MapAuditFormErrorList,UnMapAuditFormErrors,
    ProjectListByAuditFormId, DeleteProject, UpdateProject, DeleteAuditForm, UnMapProjectAuditForm, UnMapProjectAuditor, GetProjectDetails, UnMapProjectColumn
  }