import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { Button, Card, Col, Form, Row } from "react-bootstrap";
import { getLoading } from "../../../application/selector/indexSelector";
import TableShimmerLoader from "../../_common/ShimmerTable";
import { useDispatch, useSelector } from "react-redux";
import { MapProjectAuditForm, UnMapProjectAuditForm } from "../../../application/action/projectAction";
import Select from "react-select";
import { getAuditFormList, getProjectAuditFormListById } from "../../../application/selector/indexSelector";
import { format } from "date-fns";
import useAuth from "../../../hooks/useAuth";
import CreateAuditForm from "../../AuditForm/create-audit-form";

export default function AuditFormList(props) {
    const { loading, value } = useSelector(getLoading);
    const dispatch = useDispatch();
    const { auth } = useAuth()
    const ProjectAuditFormList = useSelector(getProjectAuditFormListById);
    const AuditFormsList = useSelector(getAuditFormList)
    
    const handleUnmapAuditForm =(id)=>{
dispatch(UnMapProjectAuditForm(id))
    }
    const columns = [
        {
            name: "Audit Form ID",
            selector: (row) => row.id,
            sortable: true, // Enable sorting for this column
        },

        {
            name: "Audit Form Name",
            selector: (row) => row.formName,
            sortable: true, // Enable sorting for this column
        },
        {
            name: "Created On",
            selector: (row) => (
                <div>
                    <h6 className="mb-0 fw-semibold">{format(new Date(row.modifiedOn), "dd MMM yyyy hh:mm a")}</h6>
                </div>
            ),
            sortable: true, // Enable sorting for this column
        },
        {
            name: "Action",
            selector: (row) => (
                <div className="d-flex justify-content-end">
                    <Button variant="outline-danger" className="btn-icon"
                    onClick={() => handleUnmapAuditForm( row.id)}
                    >
                        <i className="ri-delete-bin-line"></i>
                    </Button>
                </div>
            ),
            width: "200px",
        },
    ];
    // SEARCH FILTER TABLE DATA
    const [searchTerm, setSearchTerm] = useState('');
    const filteredAuditForm = ProjectAuditFormList?.filter((item) =>
        Object.values(item).some((value) =>
            value && value.toString().toLowerCase().includes(searchTerm.toLowerCase())
        )
    );

    // HANDLE DELETE AUDIT FORM
    const [data, setData] = useState([]);
    const [selectedAUF, setSelectedAUF] = useState([]);
    const associatedDataOption = AuditFormsList?.map((form) => ({
        value: form.formId,           // Using form.id for the value
        label: form.formName   // Using form.auditFormName for the label
    }));

    const handleAuditFormChange = (selectedOptions) => {
        setSelectedAUF(selectedOptions); // Update state with selected options
    };

    const handleMapAuditForm = () => {
        const Data = selectedAUF.map(element => ({
            projectId: props?.Id,
            formId: element.value, // Use `element.value` as formId from Select
            createdBy: auth.id,
            modifiedBy: auth.id
        }));
        dispatch(MapProjectAuditForm(Data)); // Dispatch the action with Data
        setSelectedAUF([]); // Optionally clear the selected options after mapping
    };
    const [showCreateAuditForm, setShowCreateAuditForm] = useState(false);

    const handleCreateAuditForm = (state) => {
        setShowCreateAuditForm(state)
    }
    return (
        <React.Fragment>
            <CreateAuditForm show={showCreateAuditForm} closeFunction={handleCreateAuditForm} />
            <div className="mb-3 d-flex justify-content-end gap-3 position-relative z-index-10">
                <div className="d-flex gap-2">
                    <Select
                        className="wt-300"
                        options={associatedDataOption} // Dropdown options
                        value={selectedAUF} // Bind to selected teams state
                        onChange={handleAuditFormChange} // Update state on selection change
                        isMulti={true} // Enable multi-select
                        isSearchable={true} // Enables searching in the dropdown
                        placeholder="Select Audit Forms" // Placeholder text
                    />

                    <Button
                        type="button"
                        variant="dark"
                        onClick={handleMapAuditForm} // Handle form mapping
                    >
                        Include
                    </Button>
                </div>

                <Button type="button" variant="primary" className="d-flex align-items-center gap-1" onClick={() => handleCreateAuditForm(true)}>
                    <i className="ri-add-line"></i> <span>Create New Audit Form</span>
                </Button>
            </div>
            <Card className="card-one">
                <Card.Header className="align-items-center justify-content-between">
                    <h4 className="main-title fs-14 mb-0">List of Audit Form</h4>
                    <div className="custom-dropdown-wrapper">
                        <div className="custom-drop-down z-index-2 wt-300">
                            <span className="dropdown-icon bg-white"><i className="ri-search-line"></i></span>
                            <Form.Control
                                type="text"
                                placeholder="Search..."
                                value={searchTerm}
                                onChange={(e) => setSearchTerm(e.target.value)}
                            />
                        </div>
                    </div>
                </Card.Header>
                <Card.Body>
                    <div className="custom-common-table">
                        {filteredAuditForm && filteredAuditForm !== undefined && (
                            <DataTable
                                columns={columns}
                                data={filteredAuditForm}
                                fixedHeader
                                highlightOnHover
                                pagination
                            />
                        )}

                        {!loading && filteredAuditForm === undefined && (
                            <TableShimmerLoader colNames={columns?.map((column) => column.name)} colsCount={1} />
                        )}
                        {loading &&
                            value === "AuditFormList" && filteredAuditForm === undefined && (
                                <TableShimmerLoader colNames={columns?.map((column) => column.name)} colsCount={1} />
                            )}
                    </div>
                </Card.Body>
            </Card>
        </React.Fragment>
    )

}