import React, { useEffect, useRef, useState } from "react";
import { Button, Form, Tab, Tabs } from "react-bootstrap";
import Agents from "./Agents/agents";
import Auditor from "./Auditor/auditor";
import Supervisor from "./Supervisor/supervisor";
import { useDispatch, useSelector } from "react-redux";
import useAuth from "../../../hooks/useAuth";
import { GetAllAuditors, GetAllRoles, GetAllSupervisor, UploadBridgeData, UsersMappingList } from "../../../application/action/userAction";
import { getUsersMappingList } from "../../../application/selector/indexSelector";
import { TeamsList } from "../../../application/action/TeamsAction";
import CreateNewUser from "./pages/create-user";
//import { useDispatch } from "react-redux";
//import { GetAllSupervisor } from "../../../application/action/userAction";
import LoadingBar from 'react-top-loading-bar';
import ProductivityBridge_Template from '../../../assets/templates/ProductivityBridge_Template.xlsx'


export default function UsersManagement() {
    const { setAuth, auth } = useAuth();
    const dispatch = useDispatch();
    const [error, setError] = useState(null);
    const [filesInfo, setFilesInfo] = useState([]);
    const fileInputRef = useRef(null);

    // HANDLE FILE UPLOAD FUNCTION
    const handleFileUpload = (event) => {
        const files = event.target.files;
        if (files.length === 0) return; // No file selected

        const formData = new FormData();
        formData.append("UploadedBy", auth.id);
        formData.append("File", files[0]); // Use the first file

        dispatch(UploadBridgeData(formData)); // Assuming `dispatch` is correctly set up

        setError(null);
        setFilesInfo([]);
    };

    // HANDLE BULK UPLOAD FUNCTION
    const handleBulkUpload = () => {
        fileInputRef.current.click();
    };
    const UserMappingList = useSelector(getUsersMappingList)

    // USEEFFECT
    useEffect(() => {
        dispatch(GetAllSupervisor(2))
        dispatch(GetAllAuditors(3))

    }, [UserMappingList]);

    // USEEFFECT
    useEffect(() => {
        dispatch(UsersMappingList())
        dispatch(GetAllRoles())
        dispatch(TeamsList())
    }, []);

     // HANDLE TAB SELECT TAB FUNCTION
    const [activeTab, setActiveTab] = useState('agent_tab');
    const handleTabSelect = (tab) => {
        setActiveTab(tab);
    };

    // HANDLE ADD NEW USER FUNCTION
    const [showAddUser, setShowAddUser] = useState(false);
    const handleAddUser = (state) => {
        setShowAddUser(state);
    };

    // PROGRESS TOP LOADER
    const loadingBarRef = useRef(null);
    useEffect(() => {
        if (loadingBarRef.current) {
            // Start the loading bar
            loadingBarRef.current.continuousStart();
    
            // Simulate a load completion or manage dynamically
            const timer = setTimeout(() => {
                loadingBarRef.current.complete();
            }, 3000); // Adjust timing as needed
    
            // Cleanup timer
            return () => clearTimeout(timer);
        }
      }, []);

    return (
        <React.Fragment>
            <CreateNewUser show={showAddUser} closeFunction={handleAddUser} />
            <LoadingBar color="#506fd9" ref={loadingBarRef} shadow={true} />
            <div className="d-md-flex align-items-center justify-content-between mb-4">
                <div>
                    <h4 className="fs-16">User Management</h4>
                </div>
                <div className="d-flex gap-2">
                    <div>
                        <Form.Control type="file" ref={fileInputRef} style={{ display: "none" }} accept=".xls,.xlsx,.csv" onChange={handleFileUpload} />
                        <Button variant="dark" onClick={handleBulkUpload}>
                            <i className="ri-file-excel-2-line align-middle"></i> Auditor Bulk Upload
                        </Button>
                    </div>
                    <div>
                        <a
                            href={ProductivityBridge_Template} 
                            download="ProductivityBridge_Template.xlsx"
                        >
                            <Button variant="outline-primary">
                                {" "}
                                <i className="ri-download-line fs-16 lh-1"> </i> 
                                 Productivity Bridge Template
                            </Button>
                        </a>

                    </div>
                    <div>
                        <Form.Control type="file" ref={fileInputRef} style={{ display: "none" }} accept=".xls,.xlsx,.csv" onChange={handleFileUpload} />
                        <Button variant="dark" onClick={handleBulkUpload}>
                            <i className="ri-file-excel-2-line align-middle"></i> Upload Productivity Bridge
                        </Button>
                    </div>
                    <Button variant="primary" onClick={() => handleAddUser(true)}>
                        <i className="ri-add-line align-middle"></i> Create New User
                    </Button>
                </div>
                {/* <Button variant="primary">
                    <i className="ri-add-line fs-16 align-middle me-1"></i>
                    <span className="align-middle">Add New</span>
                </Button> */}
            </div>

            <div className="common-tabs">
                <Tabs id="custom-styled-tabs" activeKey={activeTab} onSelect={handleTabSelect} className="common-tabs">
                    <Tab eventKey="agent_tab" title="Agent" tabClassName="custom-tab-header fw-semibold">
                        <div className="custom-tab-body">
                            <Agents />
                        </div>
                    </Tab>
                    <Tab eventKey="supervisor_tab" title="Supervisor" tabClassName="custom-tab-header fw-semibold">
                        <div className="custom-tab-body">
                            <Supervisor />
                        </div>
                    </Tab>
                    <Tab eventKey="auditor_tab" title="Auditor" tabClassName="custom-tab-header fw-semibold">
                        <div className="custom-tab-body">
                            <Auditor />
                        </div>
                    </Tab>
                </Tabs>
            </div>
        </React.Fragment>
    );
}
