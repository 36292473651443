import React, { useState, useRef, useEffect } from "react";
import OngoingAuditsData from "../../../../Json/QAAudit/ongoing-audit.json";
import { Badge, Button, Card, Form } from "react-bootstrap";
import DataTable from "react-data-table-component";
import UpdateUser from "./edit-closed-edit";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { getLoading, getOngoingCasesList } from "../../../../application/selector/indexSelector";
import TableShimmerLoader from "../../../_common/ShimmerTable";
import useAuth from "../../../../hooks/useAuth";
import { useNavigate } from "react-router-dom";
import { format } from "date-fns";
import LoadingBar from 'react-top-loading-bar';

export default function OngoingAudits() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { auth } = useAuth()
    const OngoingCasesList = useSelector(getOngoingCasesList)
    const { loading, value } = useSelector(getLoading);
    const [currentPage, setCurrentPage] = useState(1); // Track current page
    const rowsPerPage = 10; // Define rows per page
    const columns = [
        
        {
            name: "LMIS Case ID",
            selector: (row) => row.lmsCaseId,
        },
        {
            name: "Project Name",
            selector: (row) => row.projectName,
        },

        {
            name: "Audit Date",
            selector: (row) => format(new Date(row.updatedOn), "dd MMM yyyy hh:mm a"),
        },

        {
            name: "Audit Status",
            selector: (row) => (
                <Badge bg="warning" pill>Ongoing</Badge>
            )
        },

        {
            name: "Action",
            selector: (row) => (
                <div className="d-flex">
                    <Button variant="primary" className="btn-icon"
                        onClick={() => handleUpdateUser(row)}
                    ><i className="ri-pencil-line"></i></Button>
                </div>
            ),
        }
    ];

    // SEARCH FILTER TABLE DATA
    const [searchTerm, setSearchTerm] = useState('');
    const filteredOngoingAudit = OngoingCasesList?.filter((item) =>
        Object.values(item).some((value) =>
            value && value.toString().toLowerCase().includes(searchTerm.toLowerCase())
        )
    );


    // HANDLE DELETE
    // const [showUpdateUser, setShowUpdateUser] = useState(false);
    const handleUpdateUser = (state) => {
        navigate(`/create/audit/${state.id}`)
    };

    // PROGRESS TOP LOADER
    const loadingBarRef = useRef(null);
    useEffect(() => {
        if (loadingBarRef.current) {
            // Start the loading bar
            loadingBarRef.current.continuousStart();

            // Simulate a load completion or manage dynamically
            const timer = setTimeout(() => {
                loadingBarRef.current.complete();
            }, 3000); // Adjust timing as needed

            // Cleanup timer
            return () => clearTimeout(timer);
        }
    }, []);

    return (
        <React.Fragment>
            {/* <UpdateUser
            show={showUpdateUser}
            closeFunction={handleUpdateUser} /> */}
            <LoadingBar color="#506fd9" ref={loadingBarRef} shadow={true} />
            <div className="d-md-flex align-items-center justify-content-between mb-4">
                <div>
                    <h4 className="fs-16">Draft Audits</h4>
                </div>
            </div>


            <Card className="card-one">
                <Card.Header className="align-items-center justify-content-between">
                    <h4 className="main-title fs-14 mb-0">List of Draft Audits</h4>
                    <div className="custom-dropdown-wrapper">
                        <div className="custom-drop-down z-index-2 wt-300">
                            <span className="dropdown-icon bg-white"><i className="ri-search-line"></i></span>
                            <Form.Control
                                type="text"
                                placeholder="Search..."
                                value={searchTerm}
                                onChange={(e) => setSearchTerm(e.target.value)}
                            />
                        </div>
                    </div>
                </Card.Header>
                <Card.Body>
                    {filteredOngoingAudit && filteredOngoingAudit !== undefined && (
                        <DataTable
                            columns={columns}
                            data={filteredOngoingAudit}
                            fixedHeader
                            highlightOnHover
                            pagination
                            searchable
                            search
                        ></DataTable>
                    )}
                    {!loading && filteredOngoingAudit === undefined && (
                        <TableShimmerLoader colNames={columns?.map((column) => column.name)} colsCount={1} />
                    )}
                    {loading &&
                        value === "PendingResponseList" && filteredOngoingAudit === undefined && (
                            <TableShimmerLoader colNames={columns?.map((column) => column.name)} colsCount={1} />
                        )}
                </Card.Body>
            </Card>
        </React.Fragment>
    )
}

const isDateInRange = (date, range) => {
    if (range === "all") {
        return true;
    }

    const dateToCheck = moment(date);
    const now = moment();
    const daysAgo = now.subtract(range, "days");

    return dateToCheck.isAfter(daysAgo);
};