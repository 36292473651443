import React, { useState } from "react";
import DataTable from "react-data-table-component";
import SweetAlertService from "../../_common/SweetAlertService";
import ToastifyService from "../../_common/ToastifyService";
import mappedUsersData from "../../../Json/ProjectBuilder/mapped-users.json";
import { Card, Button, Form, Row, Col } from "react-bootstrap";
import MappedUserModal from "./create-mapped-user";
import TableShimmerLoader from "../../_common/ShimmerTable";
import { getAuditorList, getLoading } from "../../../application/selector/indexSelector";
import { useDispatch, useSelector } from "react-redux";
import useAuth from "../../../hooks/useAuth";
import Select from "react-select";
import { MapProjectAuditor, UnMapProjectAuditor } from "../../../application/action/projectAction";
import { getMapProjectAuditorList } from "../../../application/selector/indexSelector";
import { format } from "date-fns";


export default function MappedUsers(props) {
    const { loading, value } = useSelector(getLoading);
    const dispatch = useDispatch();
    const { auth } = useAuth();
    const ProjectAuditorList = useSelector(getMapProjectAuditorList)
    const AuditorList = useSelector(getAuditorList)
    const usersOption = AuditorList
    ?.filter((auditor) => 
        !ProjectAuditorList?.some((mapped) => mapped.auditorId === auditor.empId)
    )
    .map((data) => ({
        value: data.empId, // Replace 'id' with the actual property name you want to use as the value
        label: data.emp_fullname, // Use data.fullName or a default value like "Sanket Panday"
    }));

    
    const columns = [
        {
            name: "User Name",
            selector: (row) => row.auditorName,
            sortable: true, // Enable sorting for this column
        },
        {
            name: "Role",
            selector: (row) =>'Auditor',
            sortable: true, // Enable sorting for this column
        },
        {
            name: "Added On",
            selector: (row) =>format(new Date(row.modifiedOn), "dd MMM yyyy hh:mm a"),
            sortable: true, // Enable sorting for this column
        },
        {
            name: "Action",
            selector: (row) => (
                <div className="d-flex justify-content-end">
                    <Button variant="outline-danger" className="btn-icon" onClick={() => handleDelete(row.id)}>
                        <i className="ri-delete-bin-line"></i>
                    </Button>
                </div>
            ),
            sortable: true, // Enable sorting for this column
            width: "100px"
        },
    ];

    // SEARCH FILTER TABLE DATA
    const [searchTerm, setSearchTerm] = useState('');
   

    // HANDLE DELETE AUDIT FORM
    const [data, setData] = useState([]);
    const handleDelete = (id) => {
        SweetAlertService.showAlert(
            "Audit Form",
            "Are you sure you want to delete this User?",
            "warning"
        )
            .then((result) => {
                if (result.isConfirmed) {
                    dispatch(UnMapProjectAuditor(id))
                    ToastifyService.success(`User Deleted Successfully`);
                    const newData = [...data];
                    setData(newData);
                }
            })
            .catch((error) => {
                console.error("Error:", error);
                ToastifyService.error(`Something went wrong`);
            });
    };

    const [showMappedUser, setShowMappedUser] = useState(false);
    const handleshowMappedUser = (state) => {
        setShowMappedUser(state);
    };
    const [selectedAUF, setSelectedAUF] = useState([]);

    // const associatedDataOption = ProjectAuditorList?.map((form) => ({
    //     value: form.id,           // Using form.id for the value
    //     label: form.auditorName   // Using form.auditFormName for the label
    // }));

    const handleAuditorChange = (selectedOptions) => {
        setSelectedAUF(selectedOptions); // Update state with selected options
    };

    const handleMapAuditor = () => {
        const Data = {
            // auditForm: selectedAUF.map(element => ({
                projectId: props?.Id, // UUID format
                auditorId: selectedAUF[0].value, // UUID format
                createdBy: auth.id, // UUID format
                modifiedBy: auth.id, // UUID format
            }
        // )),
        // };
    
        dispatch(MapProjectAuditor(Data)); // Dispatch the action with Data
        setSelectedAUF([]); // Optionally clear the selected options after mapping
    };
    
    const filteredMappedUser = ProjectAuditorList?.filter((item) =>
        Object.values(item).some((value) =>
            value && value.toString().toLowerCase().includes(searchTerm.toLowerCase())
        )
    );
    return (
        <React.Fragment>
            {/* <MappedUserModal show={showMappedUser} closeFunction={handleshowMappedUser} /> */}
            <div className="mb-3 d-flex justify-content-end gap-3 position-relative z-index-10">
                <div className="d-flex gap-2">
                    <Select
                        className="wt-300"
                        options={usersOption} // Dropdown options
                        value={selectedAUF} // Bind to selected teams state
                        onChange={handleAuditorChange} // Update state on selection change
                        isMulti={true} // Enable multi-select
                        isSearchable={true} // Enables searching in the dropdown
                        placeholder="Select Auditors" // Placeholder text
                    />
                    <Button type="button" variant="dark" onClick={handleMapAuditor}>Include</Button>
                </div>
                {/* <Button type="button" variant="primary" className="d-flex align-items-center gap-1" onClick={() => handleshowMappedUser(true)}>
                    <i className="ri-add-line"></i> <span>Map Users</span>
                </Button> */}
            </div>
            <Card className="card-one">
                <Card.Header className="align-items-center justify-content-between">
                    <h4 className="main-title fs-14 mb-0">List of Mapped Users</h4>
                    <div className="custom-dropdown-wrapper">
                        <div className="custom-drop-down z-index-2 wt-300">
                            <span className="dropdown-icon bg-white"><i className="ri-search-line"></i></span>
                            <Form.Control
                                type="text"
                                placeholder="Search..."
                                value={searchTerm}
                                onChange={(e) => setSearchTerm(e.target.value)}
                            />
                        </div>
                    </div>
                </Card.Header>
                <Card.Body>
                    <div className="custom-common-table">
                        {filteredMappedUser && filteredMappedUser !== undefined && (
                            <DataTable
                                columns={columns}
                                data={filteredMappedUser}
                                fixedHeader
                                highlightOnHover
                                pagination
                            />
                        )}
                        {!loading && filteredMappedUser === undefined && (
                            <TableShimmerLoader colNames={columns?.map((column) => column.name)} colsCount={1} />
                        )}
                        {loading &&
                            value === "MappedUser" && filteredMappedUser === undefined && (
                                <TableShimmerLoader colNames={columns?.map((column) => column.name)} colsCount={1} />
                            )}
                    </div>
                </Card.Body>
            </Card>
        </React.Fragment>
    )
}