import React, { useEffect, useState } from "react";
import Header from "../../../layouts/Header";
import { Badge, Nav, Tab } from "react-bootstrap";
import PerfectScrollbar from "react-perfect-scrollbar";
import { Link } from "react-router-dom";
import useAuth from "../../../hooks/useAuth";
import { useDispatch, useSelector } from "react-redux";
import { GetAuditCaseDocument, GetClosedCasesList, GetFinalClosedCasesList, GetOngoingCasesList } from "../../../application/action/auditAction";
import { GetAllUsers } from "../../../application/action/userAction";
import { getClosedCasesList, getFinalClosedCasesList, getOngoingCasesList } from "../../../application/selector/indexSelector";
import PendingAudit from "./PendingAudit/pending-audit";
import OngoingAudits from "./OngoingAudits/ongoing-audits";
import RecentlyWorked from "./RecentlyWorked/recently-worked";

export default function QAAudit() {
    const { auth } = useAuth();
    const dispatch = useDispatch()
    const [isSidebarShow, setSidebarShow] = useState(false);
    useEffect(() => {
        dispatch(GetClosedCasesList(auth.id))
        dispatch(GetFinalClosedCasesList(auth.id))
        dispatch(GetOngoingCasesList(auth.id))
       
        dispatch(GetAllUsers())
    }, []);
    
    const OngoingCasesList = useSelector(getOngoingCasesList);
    const FinalCasesList = useSelector(getFinalClosedCasesList)
    return (
        <React.Fragment>
            <Header />

            <Tab.Container id="left-tabs-example" defaultActiveKey="pending_audit_tab">
                <div className={"main main-file-manager" + (isSidebarShow ? " show" : "")}>
                    <PerfectScrollbar className="file-sidebar page-in-tabs">
                        <Nav variant="pills">
                            <Nav.Item><Nav.Link eventKey="pending_audit_tab"><i className="ri-archive-line"></i> Pending Audit </Nav.Link></Nav.Item>
                            <Nav.Item><Nav.Link className="position-relative" eventKey="ongoing_audits_tab">
                                <i className="ri-contacts-fill"></i> Draft Audits <Badge bg="danger" pill className="position-absolute end-10">{OngoingCasesList?.length}</Badge></Nav.Link></Nav.Item>
                            <Nav.Item><Nav.Link className="position-relative" eventKey="recently_worked_tab">
                                <i className="ri-contacts-fill"></i> Closed Audits <Badge bg="secondary" pill className="position-absolute end-10">{FinalCasesList?.length}</Badge></Nav.Link></Nav.Item>
                        </Nav>
                    </PerfectScrollbar>

                    <PerfectScrollbar className="file-content p-3 p-lg-4">
                        <Link href="" className="menu-file-manager" onClick={() => setSidebarShow(!isSidebarShow)}>
                            <i className="ri-arrow-left-line"></i>
                        </Link>

                        <Tab.Content>
                            {/* PENDING AUDIT TAB */}
                            <Tab.Pane eventKey="pending_audit_tab"><PendingAudit /></Tab.Pane>

                            {/*ONGOING AUDITS TAB */}
                            <Tab.Pane eventKey="ongoing_audits_tab"><OngoingAudits /></Tab.Pane>

                            {/* RECNTLY WORKER TAB */}
                            <Tab.Pane eventKey="recently_worked_tab"><RecentlyWorked /></Tab.Pane>

                        </Tab.Content>
                    </PerfectScrollbar>
                </div>
            </Tab.Container>
        </React.Fragment>
    )
}