import React, { useState, useEffect } from "react";
import { Button, Offcanvas, Form, Row, Col } from "react-bootstrap";
import { AddUser } from "../../../../application/action/userAction";
import { getRolesList, getSupervisorList, getTeamsList } from "../../../../application/selector/indexSelector";
import { useDispatch, useSelector } from "react-redux";
import useAuth from "../../../../hooks/useAuth";
import Environment from "../../../../infrastructure/core/Environment";

export default function UpdateUser(props) {
    const RolesList = useSelector(getRolesList);
    const TeamsList = useSelector(getTeamsList);
    const SupervisorList = useSelector(getSupervisorList);
    
    const dispatch = useDispatch()
    const { auth } = useAuth();
  console.log(props?.rowData);
  
    const initialState = {
      fullName: "",
      username: "",
      roleId: 0,
      email: "",
      password: "Abteam@2024",
      empCode: "",
      supervisorId: Environment.defaultValue,
      teamId: Environment.defaultValue,
      createdBy: auth.id,
      modifiedBy: auth.id,
    };
    const [formData, setFormData] = useState(initialState);
  useEffect(() => {
    setFormData({
      ...formData,
      fullName: props?.rowData?.emp_fullname,
      email: props?.rowData?.email,
      username: props?.rowData?.emp_username,
      empCode: props?.rowData?.empId,
      roleId: props?.rowData?.roleId,
      supervisorId: props?.rowData?.supervisorId,
      teamId: props?.rowData?.teamId,
    })
  }, [props?.rowData])
    // Handle input change
    const handleInputChange = (e) => {
      const { name, value } = e.target;
      setFormData((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    };;
  
    // Handle form submission
    const handleSubmit = (e) => {
      e.preventDefault();
      dispatch(AddUser(formData))
      setFormData(initialState)
      props.closeFunction()
    };
  
    return (
      <Offcanvas
        show={props.show}
        onHide={props.closeFunction}
        placement="end"
        className="w-40"
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title className="fs-16 text-dark">
            Update User
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <Row>
            <Col>
              <div className="mb-4">
                <Form.Label>Name</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter Name"
                  name="fullName"
                  value={formData.fullName}
                  onChange={handleInputChange}
                />
              </div>
            </Col>
            <Col>
              <div className="mb-4">
                <Form.Label>Email</Form.Label>
                <Form.Control
                  type="email"
                  placeholder="Enter Email"
                  name="email"
                  value={formData.email}
                  onChange={handleInputChange}
                />
              </div>
            </Col>
          </Row>
  
          <Row>
            <Col>
              <div className="mb-4">
                <Form.Label>AMD Username</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter AMD Username"
                  name="username"
                  value={formData.username}
                  onChange={handleInputChange}
                />
              </div>
            </Col>
            <Col>
              <div className="mb-4">
                <Form.Label>EMP Code</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter emp Code"
                  name="empCode"
                  value={formData.empCode}
                  onChange={handleInputChange}
                />
              </div>
            </Col>
          </Row>
  
          <Row>
            <Col>
              <div className="mb-4">
                <Form.Label>Role</Form.Label>
                <Form.Select
                  name="roleId"
                  value={formData.roleId}
                  onChange={handleInputChange}
                >
                  <option>Choose Role</option>
                  {RolesList?.map((role) => (
                    <option key={role.id} value={Number(role.id)}>
                      {role.role}
                    </option>
                  ))}
                </Form.Select>
              </div>
            </Col>
            <Col md={6}>
              <div className="mb-4">
                <Form.Label>Team Name</Form.Label>
                <Form.Select
                  name="teamId"
                  value={formData.teamId}
                  onChange={handleInputChange}
                >
                  <option>Choose Team Name</option>
                  {TeamsList?.map((team) => (
                    <option key={team.id} value={team.id}>
                      {team.teamName}
                    </option>
                  ))}
                </Form.Select>
              </div>
            </Col>
          </Row>
  
          <Row>
            {formData.roleId == 6 && (
              <Col>
                <div className="mb-4">
                  <Form.Label>Supervisor</Form.Label>
                  <Form.Select
                    name="supervisorId"
                    value={formData.supervisorId}
                    onChange={handleInputChange}
                  >
                    <option value={Environment.defaultValue} disabled>Choose Supervisor</option>
                    {SupervisorList?.map((sup) => (
                      <option key={sup.empId} value={sup.empId}>
                        {sup.emp_fullname}
                      </option>
                    ))}
                  </Form.Select>
                </div>
              </Col>
            )}
            </Row>
        </Offcanvas.Body>
  
        {/* <div className="offcanvas-footer justify-content-start">
          <Button
            type="submit"
            variant="primary"
            className="fs-14 me-2 d-flex align-items-center"
            onClick={handleSubmit}
          >
            <i className="ri-add-line fs-18 lh-1 align-middle"></i>
            <span className="align-middle">Update User</span>
          </Button>
        </div> */}
      </Offcanvas>
    );
  }
  