export const GET_PROJECT_LIST = 'GET_PROJECT_LIST';
export const GET_AUDITFORM_LIST = 'GET_AUDITFORM_LIST';
export const GET_PROJECT_LIST_SUCCESS = 'GET_PROJECT_LIST_SUCCESS';
export const GET_AUDITFORM_LIST_SUCCESS = 'GET_AUDITFORM_LIST_SUCCESS';
export const ADD_PROJECT = 'ADD_PROJECT';
export const ADD_AUDITFORM = 'ADD_AUDITFORM';
export const DELETE_AUDITFORM = 'DELETE_AUDITFORM';
export const MAP_PROJECT_AUDIT_FORM = 'MAP_PROJECT_AUDIT_FORM';
export const MAPPED_PROJECT_AUDIT_FORM_LIST = 'MAPPED_PROJECT_AUDIT_FORM_LIST';
export const MAPPED_PROJECT_AUDIT_FORM_LIST_SUCCESS = 'MAPPED_PROJECT_AUDIT_FORM_LIST_SUCCESS';
export const MAPPED_PROJECT_COLUMN_LIST = 'MAPPED_PROJECT_COLUMN_LIST';
export const MAP_PROJECT_COLUMN = 'MAP_PROJECT_COLUMN';
export const MAPPED_PROJECT_COLUMN_LIST_SUCCESS = 'MAPPED_PROJECT_COLUMN_LIST_SUCCESS';
export const MAP_AUDITFORM_COLUMN = 'MAP_AUDITFORM_COLUMN';
export const MAPPED_AUDITFORM_COLUMN_LIST = 'MAPPED_AUDITFORM_COLUMN_LIST';
export const MAPPED_AUDITFORM_COLUMN_LIST_SUCCESS = 'MAPPED_AUDITFORM_COLUMN_LIST_SUCCESS';
export const MAP_PROJECT_AUDITOR = 'MAP_PROJECT_AUDITOR';
export const MAPPED_PROJECT_AUDITOR_LIST = 'MAPPED_PROJECT_AUDITOR_LIST';
export const MAPPED_PROJECT_AUDITOR_LIST_SUCCESS = 'MAPPED_PROJECT_AUDITOR_LIST_SUCCESS';
export const GET_PROJECT_AUDIT_FORM_LIST_BYID = 'GET_PROJECT_AUDIT_FORM_LIST_BYID';
export const GET_PROJECT_AUDIT_FORM_LIST_BYID_SUCCESS = 'GET_PROJECT_AUDIT_FORM_LIST_BYID_SUCCESS';
export const MAP_AUDITFORM_ERROR = 'MAP_AUDITFORM_ERROR';
export const MAPPED_AUDITFORM_ERROR_LIST = 'MAPPED_AUDITFORM_ERROR_LIST';
export const MAPPED_AUDITFORM_ERROR_LIST_SUCCESS = 'MAPPED_AUDITFORM_ERROR_LIST_SUCCESS';
export const PROJECT_LIST_BY_AUDITFORM_ID_SUCCESS = 'PROJECT_LIST_BY_AUDITFORM_ID_SUCCESS';
export const UNMAP_AUDITFORM_ERROR = 'UNMAP_AUDITFORM_ERROR';
export const PROJECT_LIST_BY_AUDITFORM_ID = 'PROJECT_LIST_BY_AUDITFORM_ID';
export const GET_AUDITFORM_LIST_BY_PROJID_SUCCESS = 'GET_AUDITFORM_LIST_BY_PROJID_SUCCESS';
export const GET_AUDITFORM_LIST_BY_PROJID = 'GET_AUDITFORM_LIST_BY_PROJID';
export const MAP_AUDITFORM_ERROR_TYPE = 'MAP_AUDITFORM_ERROR_TYPE';
export const UNMAP_AUDITFORM_ERROR_TYPE = 'UNMAP_AUDITFORM_ERROR_TYPE';
export const DELETE_PROJECT = 'DELETE_PROJECT';
export const UPDATE_PROJECT = 'UPDATE_PROJECT';
export const UNMAP_PROJECT_AUDIT_FORM = 'UNMAP_PROJECT_AUDIT_FORM';
export const UNMAP_PROJECT_AUDITOR = 'UNMAP_PROJECT_AUDITOR';
export const GET_PROJECT_DETAILS = 'GET_PROJECT_DETAILS';
export const GET_PROJECT_DETAILS_SUCCESS = 'GET_PROJECT_DETAILS_SUCCESS';
export const UNMAP_PROJECT_COLUMN = 'UNMAP_PROJECT_COLUMN';



export const GetProjectList = () => ({
    type: GET_PROJECT_LIST,
});
export const ProjectListSuccess = (data) => ({
    type: GET_PROJECT_LIST_SUCCESS,
    payload:data
});
export const GetProjectDetails = (data) => ({
    type: GET_PROJECT_DETAILS,
    payload:data
});
export const GetProjectDetailsSuccess = (data) => ({
    type: GET_PROJECT_DETAILS_SUCCESS,
    payload:data
});
export const AddProject = (data) => ({
    type: ADD_PROJECT,
    payload:data
});
export const AddAuditForm = (data) => ({
    type: ADD_AUDITFORM,
    payload:data
});
export const DeleteAuditForm = (data) => ({
    type: DELETE_AUDITFORM,
    payload:data
});
export const GetAuditFormList = () => ({
    type: GET_AUDITFORM_LIST,
});
export const AuditFormListSuccess = (data) => ({
    type:  GET_AUDITFORM_LIST_SUCCESS,
    payload:data
});
export const GetProjectAuditFormListById = (data) => ({
    type:  GET_PROJECT_AUDIT_FORM_LIST_BYID,
    payload:data
});
export const GetProjectAuditFormListByIdSuccess = (data) => ({
    type:  GET_PROJECT_AUDIT_FORM_LIST_BYID_SUCCESS,
    payload:data
});
export const MapProjectAuditor= (data) => ({
    type: MAP_PROJECT_AUDITOR,
    payload:data
});
export const UnMapProjectAuditor= (data) => ({
    type: UNMAP_PROJECT_AUDITOR,
    payload:data
});
export const MapProjectAuditorList = (data) => ({
    type:  MAPPED_PROJECT_AUDITOR_LIST,
    payload:data
});
export const MapProjectAuditorListSuccess = (data) => ({
    type:  MAPPED_PROJECT_AUDITOR_LIST_SUCCESS,
    payload:data
});
export const MapProjectAuditForm = (data) => ({
    type: MAP_PROJECT_AUDIT_FORM,
    payload:data
});
export const UnMapProjectAuditForm = (data) => ({
    type: UNMAP_PROJECT_AUDIT_FORM,
    payload:data
});
export const MapProjectAuditFormList = (data) => ({
    type:  MAPPED_PROJECT_AUDIT_FORM_LIST,
    payload:data
});
export const MapProjectAuditFormListSuccess = (data) => ({
    type:  MAPPED_PROJECT_AUDIT_FORM_LIST_SUCCESS,
    payload:data
});
export const MapProjectColumn = (data) => ({
    type: MAP_PROJECT_COLUMN,
    payload:data
});
export const UnMapProjectColumn = (data) => ({
    type: UNMAP_PROJECT_COLUMN,
    payload:data
});
export const MapProjectColumnList = (data) => ({
    type:  MAPPED_PROJECT_COLUMN_LIST,
    payload:data
});
export const MapProjectColumnListSuccess = (data) => ({
    type:  MAPPED_PROJECT_COLUMN_LIST_SUCCESS,
    payload:data
});
export const MapAuditFormColumn = (data) => ({
    type: MAP_AUDITFORM_COLUMN,
    payload:data
});
export const MapAuditFormColumnList = (data) => ({
    type:  MAPPED_AUDITFORM_COLUMN_LIST,
    payload:data
});
export const MapAuditFormColumnSuccess = (data) => ({
    type:  MAPPED_AUDITFORM_COLUMN_LIST_SUCCESS,
    payload:data
});
export const MapAuditFormError = (data) => ({
    type: MAP_AUDITFORM_ERROR,
    payload:data
});
export const UnMapAuditFormError = (data) => ({
    type: UNMAP_AUDITFORM_ERROR,
    payload:data
});
export const MapAuditFormErrorList = (data) => ({
    type:  MAPPED_AUDITFORM_ERROR_LIST,
    payload:data
});
export const MapAuditFormErrorListSuccess = (data) => ({
    type:  MAPPED_AUDITFORM_ERROR_LIST_SUCCESS,
    payload:data
});
export const GetProjectListByAuditFormId = (data) => ({
    type:  PROJECT_LIST_BY_AUDITFORM_ID,
    payload:data
});
export const GetProjectListByAuditFormIdSuccess = (data) => ({
    type:  PROJECT_LIST_BY_AUDITFORM_ID_SUCCESS,
    payload:data
});
export const GetAuditFormListByProjId = (data) => ({
    type: GET_AUDITFORM_LIST_BY_PROJID,
    payload:data
});
export const GetAuditFormListByProjIdSuccess = (data) => ({
    type:  GET_AUDITFORM_LIST_BY_PROJID_SUCCESS,
    payload:data
});
export const MapAuditFormErrorType = (data) => ({
    type: MAP_AUDITFORM_ERROR_TYPE,
    payload:data
});
export const UnMapAuditFormErrorType = (data) => ({
    type: UNMAP_AUDITFORM_ERROR_TYPE,
    payload:data
});
export const DeleteProject = (data) => ({
    type: DELETE_PROJECT,
    payload:data
});
export const UpdateProject = (data) => ({
    type: UPDATE_PROJECT,
    payload:data
});