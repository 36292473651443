import React, { useEffect, useState } from "react";
import { Button, Col, Form, Offcanvas, Row } from "react-bootstrap";
import Environment from "../../../../infrastructure/core/Environment";
import useAuth from "../../../../hooks/useAuth";

export default function UpdateErrorType(props) {
    const { auth } = useAuth()
    const initialState = {
        errorType: "",
        createdBy: auth.id,
        modifiedBy: auth.id,
    };
    const [formData, setFormData] = useState(initialState);
    useEffect(()=>{
        setFormData({...formData,
            errorType:props?.rowData?.errorName,
        })
          },[props?.rowData])
    // Handle input change
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };;

    return (
        <React.Fragment>
            <Offcanvas show={props.show} onHide={props.closeFunction} placement="end" className="w-30">
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title className="fs-16 text-dark">Error Categories</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <Row>
                        <Col>
                            <Col md={10}>
                                <div className="mb-4">
                                    <Form.Label>Error Type</Form.Label>
                                    <Form.Control type="text"
                                        placeholder="Enter Error Type"
                                        name="errorType"
                                        value={formData.errorType}
                                        onChange={handleInputChange} />
                                </div>

                                {/* <div className="mb-4">
                                    <Form.Label>Modified Data</Form.Label>
                                    <Form.Control type="date" />
                                </div> */}
                            </Col>
                        </Col>
                    </Row>
                </Offcanvas.Body>

                <div className="offcanvas-footer justify-content-start">
                    <Button type="submit" variant="primary" className="fs-14 me-2 d-flex align-items-center">
                        <i className="ri-add-line fs-18 lh-1 align-middle"></i>
                        <span className="align-middle">Create Error Categories</span>
                    </Button>
                </div>
            </Offcanvas>
        </React.Fragment>
    )
}