import { Navigate } from "react-router-dom";
import * as Loading from "../action/loaderAction";
import { ADD_USER, GET_ALL_AUDITOR, GET_ALL_ROLES, GET_ALL_SUPERVISOR, GET_ALL_USERGROUPS, ADD_USER_GROUP, GET_USERS_MAPPING_LIST, GET_USERS_MAPPING_LIST_SUCCESS, GetAllAuditorsSuccess, GetAllRolesSuccess, GetAllSupervisorSuccess, UPLOAD_BRIDGE_DATA, UsersMappingList, UsersMappingListSuccess, GetAllUserGroupsSuccess, GetAllUsersSuccess, GET_ALL_USERS, GET_USER_DETAILS, GetUserDetailsSuccess, GetAllUserGroups, UPDATE_USER, DELETE_USER, GetAllAuditors, GetAllSupervisor } from "../action/userAction";
import ToastifyService from "../../view/_common/ToastifyService";


const UserMappingList =
  ({ api }) =>
    ({ dispatch }) =>
      (next) =>
        async (action) => {
          if (action.type === GET_USERS_MAPPING_LIST) {
            try {
              dispatch(Loading.setLoading({ loading: true, value: "usersmappingList" }));
              const usersMappingList = await api.userAPI.UsersMappingList();
              dispatch(UsersMappingListSuccess(usersMappingList));
              dispatch(Loading.setLoading({ loading: false, value: "usersmappingList" }));
            } catch (error) {
              ToastifyService.error("Something Went wrong! ");
              console.log(error)
              if (error.response.data === "Invalid access token or refresh token") {
                localStorage.removeItem("Token");
                <Navigate to="/account/login" />;
              }
            }
          }
          next(action);
        };
const UploadBridgeData =
  ({ api }) =>
    ({ dispatch }) =>
      (next) =>
        async (action) => {
          if (action.type === UPLOAD_BRIDGE_DATA) {
            try {
              dispatch(Loading.setLoading({ loading: true, value: "usersmappingList" }));
              const usersMappingList = await api.userAPI.UploadBridgeData(action.payload);
              dispatch(UsersMappingListSuccess(usersMappingList));
              dispatch(Loading.setLoading({ loading: false, value: "usersmappingList" }));
              ToastifyService.success(`Productivity Bridge Uploaded Successfully`);
            } catch (error) {
              ToastifyService.error("Something Went wrong! ");
              console.log(error)
              if (error.response.data === "Invalid access token or refresh token") {
                localStorage.removeItem("Token");
                <Navigate to="/account/login" />;
              }
            }
          }
          next(action);
        };
const GetAllUserByRoleId =
  ({ api }) =>
    ({ dispatch }) =>
      (next) =>
        async (action) => {
          if (action.type === GET_ALL_SUPERVISOR) {
            try {
              dispatch(Loading.setLoading({ loading: true, value: "supervisorList" }));
              const supervisorList = await api.userAPI.GetAllUserByRoleId(action.payload);
              dispatch(GetAllSupervisorSuccess(supervisorList));
              dispatch(Loading.setLoading({ loading: false, value: "supervisorList" }));
            } catch (error) {
              ToastifyService.error("Something Went wrong! ");
              console.log(error)
              if (error.response.data === "Invalid access token or refresh token") {
                localStorage.removeItem("Token");
                <Navigate to="/account/login" />;
              }
            }
          } else if (action.type === GET_ALL_AUDITOR) {
            try {
              dispatch(Loading.setLoading({ loading: true, value: "auditorList" }));

              const auditorList = await api.userAPI.GetAllUserByRoleId(action.payload);
              dispatch(GetAllAuditorsSuccess(auditorList));
              dispatch(Loading.setLoading({ loading: false, value: "auditorList" }));
            } catch (error) {
              ToastifyService.error("Something Went wrong! ");
              console.log(error)
              if (error.response.data === "Invalid access token or refresh token") {
                localStorage.removeItem("Token");
                <Navigate to="/account/login" />;
              }
            }
          }
          next(action);
        };
const GetAllRoles =
  ({ api }) =>
    ({ dispatch }) =>
      (next) =>
        async (action) => {
          if (action.type === GET_ALL_ROLES) {
            try {
              dispatch(Loading.setLoading({ loading: true, value: "rolesList" }));
              const rolesList = await api.userAPI.GetAllRoles(action.payload);
              dispatch(GetAllRolesSuccess(rolesList));
              dispatch(Loading.setLoading({ loading: false, value: "rolesList" }));
            } catch (error) {
              ToastifyService.error("Something Went wrong! ");
              console.log(error)
              if (error.response.data === "Invalid access token or refresh token") {
                localStorage.removeItem("Token");
                <Navigate to="/account/login" />;
              }
            }
          }
          next(action);
        };
const GetAllUsers =
  ({ api }) =>
    ({ dispatch }) =>
      (next) =>
        async (action) => {
          if (action.type === GET_ALL_USERS) {
            try {
              dispatch(Loading.setLoading({ loading: true, value: "userslist" }));
              const userslist = await api.userAPI.GetAllUsers(action.payload);
              dispatch(GetAllUsersSuccess(userslist));
              dispatch(Loading.setLoading({ loading: false, value: "userslist" }));
            } catch (error) {
              ToastifyService.error("Something Went wrong! ");
              console.log(error)
              if (error.response.data === "Invalid access token or refresh token") {
                localStorage.removeItem("Token");
                <Navigate to="/account/login" />;
              }
            }
          }
          next(action);
        };
const AddUser =
  ({ api }) =>
    ({ dispatch }) =>
      (next) =>
        async (action) => {
          if (action.type === ADD_USER) {
            try {
              dispatch(Loading.setLoading({ loading: true, value: "usersmappingList" }));
              await api.userAPI.AddUser(action.payload);
              dispatch(UsersMappingList());
              dispatch(Loading.setLoading({ loading: false, value: "usersmappingList" }));
              ToastifyService.success(`User Added Successfully`);
            } catch (error) {
              ToastifyService.error("Something Went wrong! ");
              console.log(error)
              if (error.response.data === "Invalid access token or refresh token") {
                localStorage.removeItem("Token");
                <Navigate to="/account/login" />;
              }
            }
          }
          next(action);
        };
const DeleteUser =
  ({ api }) =>
    ({ dispatch }) =>
      (next) =>
        async (action) => {
          if (action.type === DELETE_USER) {
            try {
              dispatch(Loading.setLoading({ loading: true, value: "usersmappingList" }));
              await api.userAPI.DeleteUser(action.payload);
              dispatch(UsersMappingList())
              dispatch(GetAllAuditors(3))
              dispatch(GetAllSupervisor(2))

              dispatch(Loading.setLoading({ loading: false, value: "usersmappingList" }));
              ToastifyService.success(`User Deleted Successfully`);
            } catch (error) {
              ToastifyService.error("Something Went wrong! ");
              console.log(error)
              if (error.response.data === "Invalid access token or refresh token") {
                localStorage.removeItem("Token");
                <Navigate to="/account/login" />;
              }
            }
          }
          next(action);
        };
const UpdateUser =
  ({ api }) =>
    ({ dispatch }) =>
      (next) =>
        async (action) => {
          if (action.type === UPDATE_USER) {
            try {
              dispatch(Loading.setLoading({ loading: true, value: "usersmappingList" }));
              const response = await api.userAPI.UpdateUser(action.payload);
              dispatch(UsersMappingListSuccess(response))
              dispatch(Loading.setLoading({ loading: false, value: "usersmappingList" }));
              ToastifyService.success(`User Added Successfully`);
            } catch (error) {
              ToastifyService.error("Something Went wrong! ");
              console.log(error)
              if (error.response.data === "Invalid access token or refresh token") {
                localStorage.removeItem("Token");
                <Navigate to="/account/login" />;
              }
            }
          }
          next(action);
        };
const AddUserGroup =
  ({ api }) =>
    ({ dispatch }) =>
      (next) =>
        async (action) => {
          if (action.type === ADD_USER_GROUP) {
            try {
              dispatch(Loading.setLoading({ loading: true, value: "usersmappingList" }));
              const userGroupList = await api.userAPI.AddUserGroup(action.payload);
              dispatch(GetAllUserGroups());
              dispatch(Loading.setLoading({ loading: false, value: "usersmappingList" }));
              ToastifyService.success(`UserGroup Added Successfully`);
            } catch (error) {
              ToastifyService.error("Something Went wrong! ");
              console.log(error)
              if (error.response.data === "Invalid access token or refresh token") {
                localStorage.removeItem("Token");
                <Navigate to="/account/login" />;
              }
            }
          }
          next(action);
        };
const GetAllUserGroup =
  ({ api }) =>
    ({ dispatch }) =>
      (next) =>
        async (action) => {
          if (action.type === GET_ALL_USERGROUPS) {
            try {
              dispatch(Loading.setLoading({ loading: true, value: "rolesList" }));
              const UserGroupsList = await api.userAPI.GetAllUserGroups();
              dispatch(GetAllUserGroupsSuccess(UserGroupsList));
              dispatch(Loading.setLoading({ loading: false, value: "rolesList" }));
            } catch (error) {
              ToastifyService.error("Something Went wrong! ");
              console.log(error)
              if (error.response.data === "Invalid access token or refresh token") {
                localStorage.removeItem("Token");
                <Navigate to="/account/login" />;
              }
            }
          }
          next(action);
        };
const GetUserDetail =
  ({ api }) =>
    ({ dispatch }) =>
      (next) =>
        async (action) => {
          if (action.type === GET_USER_DETAILS) {
            try {
              dispatch(Loading.setLoading({ loading: true, value: "userDetails" }));
              const userDetails = await api.userAPI.GetUserDetail(action.payload);
              dispatch(GetUserDetailsSuccess(userDetails));
              dispatch(Loading.setLoading({ loading: false, value: "userDetails" }));
            } catch (error) {
              ToastifyService.error("Something Went wrong! ");
              console.log(error)
              if (error.response?.data === "Invalid access token or refresh token") {
                localStorage.removeItem("Token");
                <Navigate to="/account/login" />;
              }
            }
          }
          next(action);
        };
export default [
  UserMappingList, UploadBridgeData, GetAllUserByRoleId, GetAllRoles, AddUser, GetAllUserGroup, AddUserGroup, GetAllUsers, GetUserDetail, DeleteUser, UpdateUser
]