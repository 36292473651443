import React, { useEffect, useRef, useState } from "react";
import DataTable from "react-data-table-component";
import Header from "../../layouts/Header";
import { Button, Card, Form } from "react-bootstrap";
import SweetAlertService from "../_common/SweetAlertService";
import ToastifyService from "../_common/ToastifyService";
import CreateProject from "./Create/create-project";
import UpdateProject from "./Update/update-project";
import { useDispatch, useSelector } from "react-redux";
import { TeamsList } from "../../application/action/TeamsAction";
import { GetAllHeaders, GetDataSourceList } from "../../application/action/inventoryAction";
import { getProjectList, getLoading, getAuditorList } from "../../application/selector/indexSelector";
import TableShimmerLoader from "../_common/ShimmerTable";
import { DeleteProject, GetAuditFormList, GetProjectDetails, GetProjectList } from "../../application/action/projectAction";
import { GetAllAuditors } from "../../application/action/userAction";
import LoadingBar from 'react-top-loading-bar';
import { format } from "date-fns";

export default function Projects() {
    const dispatch = useDispatch()
    const [showCreateProject, setShowCreateProject] = useState(false);
    const { loading, value } = useSelector(getLoading);
    const ProjectsList = useSelector(getProjectList);

    // HANDLE CREATE PROJECT
    const handleCreateProject = (state) => {
        setShowCreateProject(state);
    };

    // USEEFFECT
    useEffect(() => {
        dispatch(GetDataSourceList());
        dispatch(TeamsList());
        dispatch(GetAllHeaders());
        dispatch(GetProjectList());
        dispatch(GetAuditFormList())
        dispatch(GetAllAuditors(3))
    }, [])

    // COLUMNS
    const columns = [
        {
            name: "Project Name",
            selector: (row) => row.projectName,
            sortable: true, // Enable sorting for this column
        },
        {
            name: "Team Name",
            selector: (row) => row.projectName,
            sortable: true, // Enable sorting for this column
        },
        {
            name: "Fields",
            selector: (row) => (
                <h6 className={` fs-14 ${row.columnCount === 0 ? "text-danger" : "text-dark fw-bold"}`} pill>
                    {row.columnCount === 0 ? "Not Mapped" : row.columnCount + " " + "mapped"}
                </h6>
                // <h6>Not Mapped</h6>
            ),
            sortable: true, // Enable sorting for this column
        },
        {
            name: "Audit Form",
            selector: (row) => (
                <h6 className={`fs-14 ${row.formCount === 0 ? "text-danger" : "text-dark fw-bold"}`} pill>
                    {row.formCount === 0 ? "Not Found" : row.formCount}
                </h6>
                // <h6>Not Found</h6>
            ),
            sortable: true, // Enable sorting for this column
        },
        {
            name: "Created On",
            selector: (row) => (
                <div>
                    <h6 className="mb-0 ">{format(new Date(row.modifiedOn), "dd MMM yyyy hh:mm a")}</h6>
                </div>
            ),
            sortable: true, // Enable sorting for this column
        },
        {
            name: "Action",
            selector: (row) => (
                <div className="d-flex justify-content-end">
                    <Button variant="primary" className="btn-icon me-2" onClick={() => handleUpdateProject(true, row)}>
                        <i className="ri-pencil-line"></i>
                    </Button>
                    <Button variant="outline-danger" className="btn-icon" onClick={() => handleDeleteProject(true, row.id)}>
                        <i className="ri-delete-bin-line"></i>
                    </Button>
                </div>
            ),
            width: "200px",
        },
    ];

    // SEARCH FILTER TABLE DATA
    const [searchTerm, setSearchTerm] = useState('');
    const [selectedProject, setSelectedProject] = useState({});
    const filteredProjectList = ProjectsList?.filter((item) =>
        Object.values(item).some((value) =>
            value && value.toString().toLowerCase().includes(searchTerm.toLowerCase())
        )
    );

    // HANDLE UPDATE PROJECT
    const [showUpdateProject, setShowUpdateProject] = useState(false);
    const handleUpdateProject = (state, data) => {
        if (data !== undefined) {
            dispatch(GetProjectDetails(data?.id))
        }
        setShowUpdateProject(state);
        setSelectedProject(data)
    };

    // HANDLE DELETE PROJECT
    const [data, setData] = useState([]);
    const handleDeleteProject = (index, id) => {
        SweetAlertService.showAlert(
            "Project",
            "Are you sure you want to delete this Project?",
            "warning"
        )
            .then((result) => {
                if (result.isConfirmed) {
                    dispatch(DeleteProject(id))
                    const newData = [...data];
                    newData.splice(index, 1);
                    setData(newData);
                }
            })
            .catch((error) => {
                console.error("Error:", error);
                ToastifyService.error(`Something went wrong`);
            });
    };

    // PROGRESS TOP LOADER
    const loadingBarRef = useRef(null);
    useEffect(() => {
        if (loadingBarRef.current) {
            // Start the loading bar
            loadingBarRef.current.continuousStart();

            // Simulate a load completion or manage dynamically
            const timer = setTimeout(() => {
                loadingBarRef.current.complete();
            }, 1000); // Adjust timing as needed

            // Cleanup timer
            return () => clearTimeout(timer);
        }
    }, []);

    return (
        <React.Fragment>
            <Header />
            <CreateProject show={showCreateProject} closeFunction={handleCreateProject} />
            <UpdateProject show={showUpdateProject} closeFunction={handleUpdateProject} selectedProject={selectedProject} />
            <LoadingBar color="#506fd9" ref={loadingBarRef} shadow={true} />
            <div className="main main-app p-3 p-lg-4">
                <div className="d-md-flex align-items-center justify-content-between mb-4">
                    <h4 className="fs-16">Projects</h4>
                    <Button variant="primary" className="d-flex align-items-center gap-1" onClick={() => handleCreateProject(true)}>
                        <i className="ri-add-line fs-16"></i>
                        <span>Create New Project</span>
                    </Button>
                </div>

                <Card className="card-one">
                    <Card.Header className="align-items-center justify-content-between">
                        <h4 className="main-title fs-14 mb-0">List of Projects</h4>
                        <div className="custom-dropdown-wrapper">
                            <div className="custom-drop-down z-index-2 wt-300">
                                <span className="dropdown-icon bg-white"><i className="ri-search-line"></i></span>
                                <Form.Control type="text" placeholder="Search..." value={searchTerm} onChange={(e) => setSearchTerm(e.target.value)} />
                            </div>
                        </div>
                    </Card.Header>
                    <Card.Body>
                        <div className="custom-common-table">
                            {filteredProjectList && filteredProjectList !== undefined && (
                                <DataTable
                                    columns={columns}
                                    data={filteredProjectList}
                                    fixedHeader
                                    highlightOnHover
                                    pagination
                                // Enable sorting
                                />
                            )}
                            {!loading && filteredProjectList === undefined && (
                                <TableShimmerLoader colNames={columns?.map((column) => column.name)} colsCount={1} />
                            )}
                            {loading &&
                                value === "ProjectList" && filteredProjectList === undefined && (
                                    <TableShimmerLoader colNames={columns?.map((column) => column.name)} colsCount={1} />
                                )}
                        </div>
                    </Card.Body>
                </Card>
            </div>
        </React.Fragment>
    );
}
