import React, { useEffect, useState, useRef } from "react";
import { Button, Card, Col, Row } from "react-bootstrap";
import ViewlistModal from "./pages/view-list";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getAuditFormListByProjId, getAuditId, getOngoingCasesList, getOpenCasesList, getPendingCasesList } from "../../../../application/selector/indexSelector";
import { AddChangeLog, GetAuditIdSuccess, GetOpenCasesList, GetPendingCasesList, PerformAudit } from "../../../../application/action/auditAction";
import EmptyState from "../../../_common/order-empty";
import useAuth from "../../../../hooks/useAuth";
import Environment from "../../../../infrastructure/core/Environment";
import FormModal from "./pages/select-form-modal";
import { GetAuditFormListByProjId } from "../../../../application/action/projectAction";
import LoadingBar from 'react-top-loading-bar';
import jwtInterceptor from "../../../../infrastructure/core/helpers/jwtInterceptor";

export default function PendingAudit() {
    const dispatch = useDispatch();
    const { auth } = useAuth();
    const navigate = useNavigate();

    // Fetch selectors for cases
    const OpenCases = useSelector(getOpenCasesList);
    const AuditId = useSelector(getAuditId);
    const PendingCasesList = useSelector(getPendingCasesList);
    const OngoingCasesList = useSelector(getOngoingCasesList);
    const AuditFormList = useSelector(getAuditFormListByProjId);
    const [selectedProject, setSelectedProject] = useState(null);
    const [ViewProject, setViewProject] = useState(null);
    const [selectedAuditId, setSelectedAuditId] = useState('');
    const [showViewlistModal, setShowViewlistModal] = useState(false);
    
    const handleViewlistModal = (state, data) => {
        setShowViewlistModal(state);
        if (data !== undefined) {
            handleViewList(data)
        }
       
    };
    const handleViewList = (data) => {
        setViewProject(data)
        dispatch(GetPendingCasesList(data?.dataSetId));
        dispatch(GetAuditFormListByProjId(data?.projectId))
    }
    const [showFormListModal, setShowFormListModal] = useState(false);
    const handleFormListModal = (state) => {
        setShowFormListModal(state);
    };

    useEffect(() => {
        dispatch(GetOpenCasesList(auth.id));
        dispatch(GetAuditIdSuccess(Environment.defaultValue))
    }, [dispatch, auth.id]);

    // Fetch pending cases when goStartAudit is called
    const goStartAudit = (data) => {
        if (data && data.dataSetId) {
            if (data.form_Count === 1) {
                dispatch(GetPendingCasesList(data.dataSetId));
                dispatch(GetAuditFormListByProjId(data.projectId))
                setSelectedProject(data);
                // dispatch(GetAuditFormListByProjId(data.projectId))
            } else {
                setShowFormListModal(true)
                setSelectedProject(data);
                dispatch(GetAuditFormListByProjId(data.projectId))
            }
        }
    };

    // Monitor changes to PendingCasesList after dispatch
    useEffect(() => {
        if (PendingCasesList?.length > 0 && selectedProject && !showFormListModal && AuditFormList && AuditFormList?.length !== 0) {
            const [caseToAudit] = PendingCasesList?.filter(
                pendingCase => !OngoingCasesList?.some(ongoingCase => ongoingCase.caseId === pendingCase.id) && pendingCase.status !== "2061156f-e154-4b21-80e6-641677224502"
            );
            if (caseToAudit) {
                caseSubmit(caseToAudit)
            } else {
                alert("No available cases to audit.");
            }
        }
    }, [PendingCasesList, OngoingCasesList, selectedProject]);

    const caseSubmit = async (caseToAudit) => {
        const auditData = {
            id: Environment.defaultValue,
            formId: AuditFormList[0]?.formId,
            projectId: selectedProject.projectId,
            caseId: caseToAudit.id,
            datasetName: selectedProject.dataSetName,
            agentId: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
            supervisorId: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
            finalScore: 0,
            auditScore: 0,
            ataScore: 0,
            rebuttalScore: 0,
            overAllRebuttal: "",
            overAllAuditorComment: "",
            assignedTo: auth.id,
            status: "2061156f-e154-4b21-80e6-641677224502",
            auditedBy: auth.id,
            ataBy: Environment.defaultValue,
            lmsCaseId: caseToAudit.lmsCaseId
        };
        try {

            const response = await jwtInterceptor.post(`${Environment.AUDIT_URL}createAudit`, auditData, {
                method: "POST",
                headers: {
                    "Access-Control-Allow-Origin": "*",
                    "Content-Type": "application/json",
                    //   authorization: `Bearer ${storedToken}`,
                },
            }
            );

            setSelectedAuditId(response.data)
            navigate(`/create/audit/${response.data}`);
            const changeData = {
                "mappingId": Environment.defaultValue,
                "auditId": response.data,
                "summary": `Case Opened`,
                "updatedBy": auth.id,
                "updatedByName": auth.user,
            }
            dispatch(AddChangeLog(changeData))

        } catch (err) {
            console.log(err);

        } finally {
            if (selectedAuditId) {
                navigate(`/create/audit/${selectedAuditId}`);
            }
        }

    }

    // // Navigate to audit details page when AuditId is set
    // useEffect(() => {
    //     if (AuditId && AuditId !== Environment.defaultValue) {
    //         navigate(`/create/audit/${AuditId}`);
    //     }
    // }, [AuditId, navigate]);


    // PROGRESS TOP LOADER
    const loadingBarRef = useRef(null);
    useEffect(() => {
        if (loadingBarRef.current) {
            // Start the loading bar
            loadingBarRef.current.continuousStart();

            // Simulate a load completion or manage dynamically
            const timer = setTimeout(() => {
                loadingBarRef.current.complete();
            }, 3000); // Adjust timing as needed

            // Cleanup timer
            return () => clearTimeout(timer);
        }
    }, []);

    return (
        <React.Fragment>
            <FormModal show={showFormListModal} closeFunction={handleFormListModal} selectedProject={selectedProject} />
            <ViewlistModal show={showViewlistModal} closeFunction={handleViewlistModal} selectedProject={ViewProject} />
            <LoadingBar color="#506fd9" ref={loadingBarRef} shadow={true} />
            <div className="d-md-flex align-items-center justify-content-between mb-4">
                <div>
                    <h4 className="fs-16 mb-1">Pending Audit</h4>
                    <h6 className="fs-14 text-black-50">Project wise pending cases to be audited</h6>
                </div>
            </div>
            {OpenCases && OpenCases.length !== 0 ? (
                <Row className="row-cols-auto g-3 g-xl-4 pt-2">
                    {OpenCases.map((data, index) => (
                        <>{data.cases_Count !== 0 && (
                            <Col lg={3} md={3} sm={6} key={index}>
                                <Card className="card-one">
                                    <Card.Body>
                                        <h2 className="mb-2 fs-24">
                                            <span className="fw-semibold">{data.cases_Count}  </span>
                                            <span className="fs-14">Open for Audit</span>
                                        </h2>
                                        <h3 className="fs-16 mb-4">{data.projectName}</h3>
                                        <div className="d-flex gap-2">
                                            <Button
                                                variant="primary"
                                                className="flex-fill"
                                                onClick={() => goStartAudit(data)}
                                                disabled={data.cases_Count === 0 || data.form_Count === 0} // Disable button if cases_Count is 0
                                            >
                                                Start Audit
                                            </Button>
                                            <Button
                                                variant="secondary"
                                                className="flex-fill"
                                                onClick={() => handleViewlistModal(true, data)}
                                                disabled={data.cases_Count === 0 || data.form_Count === 0} // Disable button if cases_Count is 0
                                            >
                                                View List
                                            </Button>
                                        </div>
                                    </Card.Body>
                                </Card>
                            </Col>
                        )}</>

                    ))}

                </Row>
            ) : (
                <EmptyState />
            )}
        </React.Fragment>
    );
}
