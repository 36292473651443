import React, { useState, useEffect, useRef } from "react";
import { Button, Card, Form } from "react-bootstrap";
import DataTable from "react-data-table-component";
import ToastifyService from "../../../_common/ToastifyService";
import SweetAlertService from "../../../_common/SweetAlertService";
import CreateNewUser from "../pages/create-user";
import UpdateUser from "../pages/update-user";
import { useDispatch, useSelector } from "react-redux";
//import { GetAllAuditors, GetAllRoles, GetAllSupervisor, UploadBridgeData, UsersMappingList } from "../../../../application/action/userAction";
import { getUsersMappingList } from "../../../../application/selector/indexSelector";
import { Link } from "react-router-dom";
import { format } from "date-fns";
import useAuth from "../../../../hooks/useAuth";
import { DeleteUser } from "../../../../application/action/userAction";
//import { TeamsList } from "../../../../application/action/TeamsAction";

export default function Agents() {
    const { setAuth, auth } = useAuth();
    const dispatch = useDispatch();

    const [error, setError] = useState(null);

    //const validFileExtensions = ['.xls', '.xlsx', '.csv'];

    const fileInputRef = useRef(null);
    const [showUpdateUser, setShowUpdateUser] = useState(false);
    const [editRowData, setEditRowData] = useState({});
    const UserMappingList = useSelector(getUsersMappingList)

    // HANDLE EDIT FUNCTION
    const handleEdit = (state, row) => {
        setEditRowData(row);
        setShowUpdateUser(state);
    };

    // HANDLE SAVE EDIT FUNCTION
    const [data, setData] = useState([]);
    const handleSaveEdit = (updatedData) => {
        const newData = [...data];
        newData[editRowData.index] = updatedData;
        setData(newData);
    };

    // HANDLE DELETE FUNCTION
    const handleDelete = (row) => {
        SweetAlertService.showAlert(
            "Agent",
            "Are you sure you want to delete this Agent?",
            "warning"
        )
            .then((result) => {
                if (result.isConfirmed) {
        
                dispatch(DeleteUser(row.empId))
                }
            })
            .catch((error) => {
                console.error("Error:", error);
                ToastifyService.error(`Something went wrong`);
            });
    };

    // HANDLE ADD NEW USER FUNCTION
    const [showAddUser, setShowAddUser] = useState(false);
    const handleAddUser = (state) => {
        setShowAddUser(state);
    };

    const columns = [
        {
            name: "ID",
            selector: (row) => <div>{row.empId}</div>,
            width: "50px",
        },
        {
            name: "Employee Name",
            selector: (row) => (
                <div>
                    <h6 className="mb-0 fw-semibold">
                        <Link>{row.emp_fullname}</Link>
                    </h6>
                    <span className="fs-xs text-secondary">{row.emp_username}</span>
                </div>
            ),
        },
        {
            name: "Supervisor",
            selector: (row) => row.supervisor_fullname,
        },
        {
            name: "Team",
            selector: (row) => row.teamName,
        }, {
            name: "Modified On",
            selector: (row) => format(new Date(row.modifiedOn), "dd MMM yyyy hh:mm a"),
        },
        {
            name: "Action",
            selector: (row) => (
                <div className="d-flex justify-content-end">
                    <Button
                        variant="primary"
                        className="btn-icon me-2"
                        onClick={() => handleEdit(true, row)}
                    >
                        <i className="ri-pencil-line"></i>
                    </Button>
                    <Button
                        variant="outline-danger"
                        className="btn-icon"
                        onClick={() => handleDelete(row)}
                    >
                        <i className="ri-delete-bin-line"></i>
                    </Button>
                </div>
            ),
            width: "200px",
        },
    ];
    const [searchTerm, setSearchTerm] = useState('');
    const filteredUserMappingList = UserMappingList?.filter((item) =>
        Object.values(item).some((value) =>
            value && value.toString().toLowerCase().includes(searchTerm.toLowerCase())
        )
    );
    return (
        <React.Fragment>
            <CreateNewUser show={showAddUser} closeFunction={handleAddUser} />
            <UpdateUser
                show={showUpdateUser}
                closeFunction={() => setShowUpdateUser(false)}
                rowData={editRowData}
                columns={columns}
            />
            <div className="d-md-flex align-items-center justify-content-between mb-4">
                <div><h4 className="fs-16">Agent</h4></div>
                <div className="custom-drop-down z-index-2 wt-300">
                    {/* <span className=" d-flex dropdown-icon bg-white"><i className="ri-search-line"></i></span> */}
                    <Form.Control type="text" placeholder="Search..." value={searchTerm} onChange={(e) => setSearchTerm(e.target.value)} />
                </div>
            </div>


            <Card className="card-one">

                <Card.Body>

                    {error && <p className="text-center" style={{ color: "red" }}>{error}</p>}
                    {filteredUserMappingList?.length > 0 ? (
                        <DataTable
                            columns={columns}
                            data={filteredUserMappingList}
                            pagination
                            highlightOnHover
                        />
                    ) : (
                        !error && <p className="text-center">No records to display</p>
                    )}
                </Card.Body>
            </Card>
        </React.Fragment>
    )
};