export const GET_ERROR_LIST = 'GET_ERROR_LIST';
export const ERROR_UPLOAD = 'ERROR_UPLOAD';
export const ERROR_CATEGORY_UPLOAD = 'ERROR_CATEGORY_UPLOAD';
export const GET_ERROR_LIST_SUCCESS = 'GET_ERROR_LIST_SUCCESS';
export const GET_ERROR_CATEGORY_LIST = 'GET_ERROR_CATEGORY_LIST';
export const GET_ERROR_CATEGORY_SUCCESS = 'GET_ERROR_CATEGORY_SUCCESS';
export const SUB_ERROR_UPLOAD = 'SUB_ERROR_UPLOAD';
export const GET_SUB_ERROR_TYPE_SUCCESS = 'GET_SUB_ERROR_TYPE_SUCCESS';
export const GET_SUB_ERROR_TYPE_LIST = 'GET_SUB_ERROR_TYPE_LIST';
export const ADD_POINTS = 'ADD_POINTS';
export const GET_POINT_LIST = 'GET_POINT_LIST';
export const GET_POINT_LIST_SUCCESS = 'GET_POINT_LIST_SUCCESS';
export const DELETE_ERROR = 'DELETE_ERROR';
export const DELETE_ERROR_CATEGORY = 'DELETE_ERROR_CATEGORY';
export const DELETE_ERROR_SUBCATEGORY = 'DELETE_ERROR_SUBCATEGORY';

export const ErrorUpload = (data) => ({
    type:ERROR_UPLOAD,
    payload:data
});
export const ErrorList = (data) => ({
    type: GET_ERROR_LIST,
    payload:data
});
export const DeleteError = (data) => ({
    type: DELETE_ERROR,
    payload:data
});
export const DeleteErrorCategory = (data) => ({
    type: DELETE_ERROR_CATEGORY,
    payload:data
});
export const DeleteErrorSubCategory = (data) => ({
    type: DELETE_ERROR_SUBCATEGORY,
    payload:data
});
export const ErrorListSuccess = (data) => ({
    type: GET_ERROR_LIST_SUCCESS,
    payload:data
});
export const ErrorCategoryList = (data) => ({
    type: GET_ERROR_CATEGORY_LIST,
    payload:data
});
export const ErrorCategoryUpload = (data) => ({
    type:ERROR_CATEGORY_UPLOAD,
    payload:data
});
export const ErrorCategoryListSuccess = (data) => ({
    type: GET_ERROR_CATEGORY_SUCCESS,
    payload:data
});
export const SubErrorUpload = (data) => ({
    type:SUB_ERROR_UPLOAD,
    payload:data
});
export const SubErrorTypeList = (data) => ({
    type: GET_SUB_ERROR_TYPE_LIST,
    payload:data
});
export const SubErrorListSuccess = (data) => ({
    type: GET_SUB_ERROR_TYPE_SUCCESS,
    payload:data
});
export const AddPoints = (data) => ({
    type:ADD_POINTS,
    payload:data
});
export const GetPointsList = (data) => ({
    type: GET_POINT_LIST,
    payload:data
});
export const GetPointsListSuccess = (data) => ({
    type: GET_POINT_LIST_SUCCESS,
    payload:data
});